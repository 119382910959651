import React, { useState, useEffect } from 'react';
import { Brain } from 'lucide-react';

const JobDescriptionLoader = () => {
  const [processText, setProcessText] = useState('Initializing AI...');
  const [dots, setDots] = useState('');
  
  const processes = [
    'Crafting the perfect description...',
    'Enhancing your content...',
    'Optimizing language...',
    'Creating impact...'
  ];

  useEffect(() => {
    const processInterval = setInterval(() => {
      setProcessText(processes[Math.floor(Math.random() * processes.length)]);
    }, 2500);

    const dotInterval = setInterval(() => {
      setDots(prev => prev.length >= 3 ? '' : prev + '.');
    }, 500);

    return () => {
      clearInterval(processInterval);
      clearInterval(dotInterval);
    };
  }, []);

  return (
    <div className="fixed inset-0">
      <div className="flex flex-col items-center justify-center h-full p-4">
        {/* Large central animation */}
        <div className="relative w-48 h-48 mb-12">
          {/* Outer ring */}
          <div className="absolute inset-0 border-8 border-transparent border-t-blue-500/30 rounded-full animate-spin" 
               style={{ animationDuration: '4s' }} />
          
          {/* Middle ring */}
          <div className="absolute inset-4 border-8 border-transparent border-t-indigo-500/40 rounded-full animate-spin"
               style={{ animationDirection: 'reverse', animationDuration: '3s' }} />
          
          {/* Inner ring */}
          <div className="absolute inset-8 border-8 border-transparent border-t-purple-500/50 rounded-full animate-spin"
               style={{ animationDuration: '2s' }} />

          {/* Center icon */}
          <div className="absolute inset-0 flex items-center justify-center">
            <Brain className="text-indigo-600 animate-pulse" size={48} />
          </div>
        </div>

        {/* Text content */}
        <div className="text-center max-w-md">
          <h2 className="text-2xl font-semibold bg-gradient-to-r from-blue-600 to-indigo-600 bg-clip-text text-transparent mb-6">
            {processText}<span>{dots}</span>
          </h2>
          
          {/* Pulse indicators */}
          <div className="flex items-center justify-center space-x-3 mb-6">
            <div className="w-3 h-3 bg-blue-400 rounded-full animate-pulse" />
            <div className="w-3 h-3 bg-indigo-400 rounded-full animate-pulse delay-150" />
            <div className="w-3 h-3 bg-purple-400 rounded-full animate-pulse delay-300" />
          </div>
          
          <p className="text-gray-300 text-lg">
            Our AI is optimizing your job description for maximum impact
          </p>
        </div>
      </div>
    </div>
  );
};

export default JobDescriptionLoader;