import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { Authenticator } from "@aws-amplify/ui-react";
import Modal from "react-modal";
import { Button } from "@mui/material";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import Loader from "../Loader";
import host from "../../global";

Modal.setAppElement("#root");

function App() {
  const [shortlisted, setShortlisted] = useState([]);
  const [retrofit, setRetrofit] = useState([]);
  const [selectedCandidates, setSelectedCandidates] = useState([]);
  const [invalidData, setInvalidData] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null);
  const [newEmail, setNewEmail] = useState("");
  const { upload_id } = useParams();
  const navigate = useNavigate();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentReport, setCurrentReport] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const accessToken = Cookies.get("accessToken");
  const [editingCandidate, setEditingCandidate] = useState(null);
  const [editingInvalidIndex, setEditingInvalidIndex] = useState(null);
  const [newInvalidEmail, setNewInvalidEmail] = useState("");
  const [editingInvalidCandidate, setEditingInvalidCandidate] = useState(null);
  const [resumeScoreThreshold,setResumeScoreThreshold] = useState(60)

  // Start Save editing invalid candidate email in proper format
  const startEditingInvalidEmail = (index, candidate) => {
    setEditingInvalidIndex(index);
    setNewInvalidEmail(candidate.candidate_email || "");
    setEditingInvalidCandidate(candidate);
  };
  const saveInvalidEmailChange = async (index) => {
    if (!newInvalidEmail || !editingInvalidCandidate) {
      toast.error("Please enter a valid email");
      return;
    }
  
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(newInvalidEmail)) {
      toast.error("Please enter a valid email format");
      return;
    }
  
    if (newInvalidEmail === editingInvalidCandidate.candidate_email) {
      toast.info("No changes made to email");
      setEditingInvalidIndex(null);
      setNewInvalidEmail("");
      setEditingInvalidCandidate(null);
      return;
    }
  
    try {
      const params = new URLSearchParams({
        upload_id: upload_id,
        new_email: newInvalidEmail,
      });
  
      // Include both name and old email for matching
      if (editingInvalidCandidate.name && editingInvalidCandidate.name !== "not provided") {
        params.append("candidate_name", editingInvalidCandidate.name);
      }
  
      if (editingInvalidCandidate.candidate_email && editingInvalidCandidate.candidate_email !== "not provided") {
        params.append("old_email", editingInvalidCandidate.candidate_email);
      }
  
      await axios.put(
        `${host}/updatecandidate`,
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          params: params,
        }
      );
  
      const updatedInvalidData = invalidData.map((candidate) => {
        if (candidate === editingInvalidCandidate) {
          return { ...candidate, candidate_email: newInvalidEmail };
        }
        return candidate;
      });
  
      setInvalidData(updatedInvalidData);
  
      setEditingInvalidIndex(null);
      setNewInvalidEmail("");
      setEditingInvalidCandidate(null);
      toast.success("Email updated successfully!");
  
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      console.error("Error details:", error.response?.data);
      if (error.response) {
        const status = error.response.status;
        const detail = error.response.data.detail;
        if (status === 401) {
          await new Promise((resolve) => {
            Cookies.remove("accessToken");
            resolve();
          });
          toast.error(`Unauthorized: ${detail}`);
        } else {
          toast.error(`Error: ${detail}`);
        }
      } else {
        toast.error("An unexpected error occurred. Please try again.");
      }
      setEditingInvalidIndex(null);
      setNewInvalidEmail("");
      setEditingInvalidCandidate(null);
    }
  };

  useEffect(() => {
    const fetchCandidates = async () => {
      try {
        const response = await axios.get(`${host}/resumes`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            upload_id: upload_id,
          },
        });
        const fetchedCandidates = response.data.data;
        let score_threshold = response.data.resume_score_threshold
        setResumeScoreThreshold(response.data.resume_score_threshold)

        if (Array.isArray(fetchedCandidates)) {
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          const isValidEmail = (email) => {
            return email && typeof email === "string" && emailRegex.test(email);
          };
          setShortlisted(
            fetchedCandidates.filter(
              (c) => c.score >= score_threshold && isValidEmail(c.candidate_email)
            )
          );
          setRetrofit(
            fetchedCandidates.filter(
              (c) =>
                c.score < score_threshold &&
                c.score !== null &&
                isValidEmail(c.candidate_email)
            )
          );
          setInvalidData(
            fetchedCandidates.filter(
              (c) =>
                !isValidEmail(c.candidate_email) ||
                c.score === null ||
                c.candidate_email === "not provided" ||
                c.candidate_email === ""
            )
          );
          setIsLoading(false);
        } else {
          console.error("Unexpected data format:", fetchedCandidates);
        }
      } catch (error) {
        if (error.response) {
          const status = error.response.status;
          const detail = error.response.data.detail;
          if (status === 401) {
            await new Promise((resolve) => {
              Cookies.remove("accessToken");
              resolve();
            });
            toast.error(`Unauthorized: ${detail}`);
          } else if (status === 404) {
            toast.warning(`Not Found: ${detail}`);
          } else {
            toast.error(`Error: ${detail}`);
          }
        } else {
          toast.error("An unexpected error occurred. Please try again.");
        }
      }
    };
    fetchCandidates();
  }, [upload_id, accessToken, navigate]);

  const scheduleInterviewForAll = async () => {
    try {
      await axios.post(
        `${host}/schedule_interviews`,
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            upload_id: upload_id,
          },
        }
      );
      toast.success("Interviews have been scheduled for all candidates.");
      setTimeout(() => {
        navigate("/hr/dashboard");
      }, 2000);
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        const detail = error.response.data.detail;
        if (status === 401) {
          await new Promise((resolve) => {
            Cookies.remove("accessToken");
            resolve();
          });
          toast.error(`Unauthorized: ${detail}`);
        } else if (status === 404) {
          toast.warning(`Not Found: ${detail}`);
        } else {
          toast.error(`Error: ${detail}`);
        }
      } else {
        toast.error("An unexpected error occurred. Please try again.");
      }
    }
  };

  const handleReportClick = (candidate) => {
    setCurrentReport(candidate.report);
    setModalIsOpen(true);
  };
  const closeModal = () => {
    setModalIsOpen(false);
    setCurrentReport(null);
  };

  const startEditingEmail = (index, candidate) => {
    setEditingIndex(index);
    setNewEmail(candidate.candidate_email || "");
    setEditingCandidate(candidate);
  };

  const saveEmailChange = async (index) => {
    if (!newEmail || !editingCandidate) {
      toast.error("Please enter a valid email");
      return;
    }
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(newEmail)) {
      toast.error(
        "Please enter a valid email address (e.g., example@gmail.com or official@example.com)"
      );
      return;
    }
    if (newEmail === editingCandidate.candidate_email) {
      toast.info("No changes made to email");
      setEditingIndex(null);
      setNewEmail("");
      setEditingCandidate(null);
      return;
    }
    try {
      const params = new URLSearchParams({
        upload_id: upload_id,
        old_email: editingCandidate.candidate_email,
        new_email: newEmail,
      });
      if (editingCandidate.name && editingCandidate.name !== "not provided") {
        params.append("candidate_name", editingCandidate.name);
      }
      await axios.put(
        `${host}/updatecandidate`,
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          params: params,
        }
      );
      // Update the candidate in all arrays after email change
      const updateCandidateInArray = (array) => {
        return array.map((candidate) => {
          if (candidate.candidate_email === editingCandidate.candidate_email) {
            return { ...candidate, candidate_email: newEmail };
          }
          return candidate;
        });
      };
      setShortlisted(updateCandidateInArray(shortlisted));
      setRetrofit(updateCandidateInArray(retrofit));
      setInvalidData(updateCandidateInArray(invalidData));

      setEditingIndex(null);
      setNewEmail("");
      setEditingCandidate(null);
      toast.success("Email updated successfully!");
    } catch (error) {
      console.error("Error details:", error.response?.data);
      if (error.response) {
        const status = error.response.status;
        const detail = error.response.data.detail;
        if (status === 401) {
          await new Promise((resolve) => {
            Cookies.remove("accessToken");
            resolve();
          });
          toast.error(`Unauthorized: ${detail}`);
        } else if (status === 404) {
          toast.warning(`Not Found: ${detail}`);
        } else if (status === 422) {
          toast.error(`Invalid request: ${detail}`);
        } else {
          toast.error(`Error: ${detail}`);
        }
      } else {
        toast.error("An unexpected error occurred. Please try again.");
      }
      setEditingIndex(null);
      setNewEmail("");
      setEditingCandidate(null);
    }
  };
  const handleCheckboxChange = (candidate) => {
    setSelectedCandidates((prevSelected) => {
      if (prevSelected.includes(candidate)) {
        return prevSelected.filter((c) => c !== candidate);
      } else {
        return [...prevSelected, candidate];
      }
    });
  };

  const deleteSelectedCandidates = async () => {
    const selectedEmails = selectedCandidates.map(
      (candidate) => candidate.candidate_email
    );
    try {
      await axios.delete(`${host}/deletecandidateBulkUpload`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params: {
          upload_id: upload_id,
          emails: selectedEmails.join(","),
        },
      });
      toast.success("Selected candidates deleted successfully");
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        const detail = error.response.data.detail;
        if (status === 401) {
          await new Promise((resolve) => {
            Cookies.remove("accessToken");
            resolve();
          });
          toast.error(`Unauthorized: ${detail}`);
        } else if (status === 404) {
          toast.warning(`Not Found: ${detail}`);
        } else {
          toast.error(`Error: ${detail}`);
        }
      } else {
        toast.error("An unexpected error occurred. Please try again.");
      }
    }
  };

  const deleteSingleCandidate = async (candidate) => {
    try {
      await axios.delete(`${host}/deletecandidateBulkUpload`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params: {
          upload_id: upload_id,
          emails: candidate.candidate_email || "",
        },
      });
      toast.success("Candidate deleted successfully");
      setInvalidData(invalidData.filter((c) => c !== candidate));
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        const detail = error.response.data.detail;
        if (status === 401) {
          await new Promise((resolve) => {
            Cookies.remove("accessToken");
            resolve();
          });
          toast.error(`Unauthorized: ${detail}`);
        } else if (status === 404) {
          toast.warning(`Not Found: ${detail}`);
        } else {
          toast.error(`Error: ${detail}`);
        }
      } else {
        toast.error("An unexpected error occurred. Please try again.");
      }
    }
  };

  // Shortlist selected candidates
  const shortlistSelectedCandidates = () => {
    const candidatesToShortlist = selectedCandidates.filter(
      (candidate) => candidate.score < resumeScoreThreshold
    );
    setRetrofit(retrofit.filter((c) => !candidatesToShortlist.includes(c)));
    setShortlisted([...shortlisted, ...candidatesToShortlist]);
    setSelectedCandidates([]);
  };
  if (isLoading) {
    return <Loader />;
  }

  return (
    <Authenticator>
      <div className="p-8 company-admin-container">
        <div className="rounded-lg content glass-effect">
          <div className="maincontent">
          <div className="container mx-auto px-4">
          <h1 className="text-white font-bold mb-4">Candidate Management</h1>
            {/* Validation Notice */}
              

              {/* Invalid data candidates table */}
              {invalidData.length > 0 && (
                <div className="table-container">
                  <div className="table-header">
                    <h2 className="text-white font-semibold mb-1">Invalid Data Candidates</h2>
                    {invalidData.length > 0 && (
                    <div>
                        <p className="text-red-400 font-light text-xs mb-3">
                          Please validate the invalid candidates before proceeding
                          further.
                        </p>
                      </div>
                    )}
                    <table className="responsive-table">
                      <thead>
                        <tr>
                          <th style={{ width: "80px" }}>Sr. No</th>
                          <th style={{ width: "150px" }}>Name</th>
                          <th style={{ width: "300px" }}>Email</th>
                          <th style={{ textAlign: "center", width: "80px" }}>
                            Score
                          </th>
                          <th style={{ width: "100px" }}>Report</th>
                          <th style={{ width: "200px" }}>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {invalidData.map((candidate, index) => (
                          <tr key={index}>
                            <td style={{ width: "80px" }}>{index + 1}</td>
                            <td style={{ width: "150px" }}>{candidate.name}</td>
                            <td style={{ width: "300px" }}>
                              {editingInvalidIndex === index ? (
                                <input
                                  type="email"
                                  value={newInvalidEmail}
                                  onChange={(e) =>
                                    setNewInvalidEmail(e.target.value)
                                  }
                                  placeholder="Enter new email"
                                  className="email-input"
                                  required
                                  style={{ background: "none", backgroundColor: "transparent"}}
                                />
                              ) : (
                                candidate.candidate_email || "Not provided"
                              )}
                            </td>
                            <td style={{ textAlign: "center", width: "80px" }}>
                              {candidate.score || "N/A"}
                            </td>
                            <td style={{ width: "100px" }}>
                              <div
                                className="tooltip-icon"
                                onClick={() => handleReportClick(candidate)}
                              >
                                <VisibilityRoundedIcon
                                  style={{ height: "30px", width: "30px" }}
                                />
                                <span className="tooltip-text">
                                  View Report
                                </span>
                              </div>
                            </td>
                            <td style={{ width: "200px" }}>
                              {editingInvalidIndex === index ? (
                                <Button
                                  className="app-button"
                                  onClick={() => saveInvalidEmailChange(index)}
                                  disabled={!newInvalidEmail}
                                >
                                  Save
                                </Button>
                              ) : (
                                <Button
                                  className="app-button"
                                  onClick={() =>
                                    startEditingInvalidEmail(index, candidate)
                                  }
                                >
                                  Edit Email
                                </Button>
                              )}
                              <Button
                                className="app-button"
                                onClick={() => deleteSingleCandidate(candidate)}
                              >
                                Delete
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
              

              {/* Shortlisted candidates table */}
              {shortlisted.length > 0 && (
                <div className="table-container">
                  <div className="table-header">
                    <h2 className="text-white mb-3 font-semibold">Shortlisted Candidates</h2>
                    <table className="responsive-table">
                      <thead>
                        <tr>
                          <th style={{ width: "100px", textAlign: "center" }}>Sr. No</th>
                          <th style={{ width: "150px", textAlign: "center" }}>Candidate Name</th>
                          <th style={{ width: "300px", textAlign: "center" }}>Email</th>
                          <th style={{ textAlign: "center", width: "80px" }}>
                            Score
                          </th>
                          <th style={{ width: "100px", textAlign: "center" }}>Report</th>
                          <th style={{ width: "100px", textAlign: "center" }}>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {shortlisted.map((candidate, index) => (
                          <tr key={index}>
                            <td style={{ width: "100px" }}>{index + 1}</td>
                            <td style={{ width: "150px", textAlign: "left" }}>{candidate.name}</td>
                            <td style={{ width: "300px", textAlign: "left", paddingLeft: "120px" }}>
                              {editingIndex === index ? (
                                <input
                                  type="email"
                                  value={newEmail}
                                  onChange={(e) => setNewEmail(e.target.value)}
                                  placeholder="Enter new email"
                                  required
                                  style={{ background: "none", backgroundColor: "transparent"}}
                                />
                              ) : (
                                candidate.candidate_email
                              )}
                            </td>
                            <td style={{ textAlign: "center", width: "80px" }}>
                              {candidate.score}
                            </td>
                            <td style={{ width: "100px" }}>
                              <div
                                className="tooltip-icon"
                                onClick={() => handleReportClick(candidate)}
                              >
                                <VisibilityRoundedIcon
                                  style={{ height: "30px", width: "30px" }}
                                />
                                <span className="tooltip-text">
                                  View Report
                                </span>
                              </div>
                            </td>
                            <td style={{ width: "100px" }}>
                              {editingIndex === index ? (
                                <button
                                  className="button"
                                  onClick={() => saveEmailChange(index)}
                                  disabled={!newEmail}
                                >
                                  <span>Save</span>
                                </button>
                              ) : (
                                <button
                                  className="button"
                                  onClick={() =>
                                    startEditingEmail(index, candidate)
                                  }
                                >
                                  <span>Edit</span>
                                </button>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>

                    {/* Conditionally render the Schedule Interviews for All button */}
                    {invalidData.length === 0 && retrofit.length === 0 && (
                      <button
                        className="button"
                        onClick={scheduleInterviewForAll}
                      >
                        <span>Schedule Interviews for All</span>
                      </button>
                    )}

                    {/* Report modal dialog */}
                    <Dialog open={modalIsOpen} onClose={closeModal}  PaperProps={{
                      style: {
                        background:
                        "linear-gradient(233.57deg, rgba(34, 52, 97, 0.25) -5.01%, rgba(11, 10, 31, 0.05) 100%)",
                      opacity: 1,
                      backdropFilter: "blur(500px)",
                      }
                    }}>
                      <DialogTitle className="text-white">Candidate Report</DialogTitle>
                      <DialogContent dividers className="text-white mt-2">
                        {currentReport ? (
                          <p>{currentReport}</p>
                        ) : (
                          <p>No report available</p>
                        )}
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={closeModal} color="primary">
                          Close
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </div>
                </div>
              )}

              {/* Retrofit candidates table */}
              {retrofit.length > 0 && (
                <div className="table-container">
                  <div className="table-header">
                    <h2 className="font-semibold text-white mb-3">RetroFit Candidates</h2>
                    {selectedCandidates.length > 0 && (
                      <div style={{ display: "flex", gap: "10px" }}>
                        <button
                          className="button"
                          onClick={deleteSelectedCandidates}
                        >
                          <span>Delete Selected Candidates</span>
                        </button>
                        <button
                          className="button"
                          onClick={shortlistSelectedCandidates}
                        >
                          <span>Shortlist Selected Candidates</span>
                        </button>
                      </div>
                    )}
                    <table className="responsive-table">
                      <thead>
                        <tr>
                          <th style={{ width: "100px", textAlign: "center" }}>Sr. No</th>
                          <th style={{ width: "150px", textAlign: "center" }}>Name</th>
                          <th style={{ width: "300px", textAlign: "center" }}>Email</th>
                          <th style={{ textAlign: "center", width: "80px" }}>
                            Score
                          </th>
                          <th style={{ width: "100px", textAlign: "center" }}>Report</th>
                          <th style={{ width: "100px", textAlign: "center" }}>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {retrofit.map((candidate, index) => (
                          <tr key={index}>
                            <td style={{ width: "100px" }}>{index + 1}</td>
                            <td style={{ width: "150px", textAlign: "left" }}>{candidate.name}</td>
                            <td style={{ width: "300px", textAlign: "left", paddingLeft: "120px" }}>
                              {candidate.candidate_email}
                            </td>
                            <td style={{ textAlign: "center", width: "80px" }}>
                              {candidate.score}
                            </td>
                            <td style={{ width: "100px" }}>
                              <div
                                className="tooltip-icon"
                                onClick={() => handleReportClick(candidate)}
                              >
                                <VisibilityRoundedIcon
                                  style={{ height: "30px", width: "30px" }}
                                />
                                <span className="tooltip-text">
                                  View Report
                                </span>
                              </div>
                            </td>

                            <td style={{ width: "100px" }}>
                              <input
                                type="checkbox"
                                checked={selectedCandidates.includes(candidate)}
                                onChange={() => handleCheckboxChange(candidate)}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Authenticator>
  );
}

export default App;
