import React from 'react';
import { useState } from 'react';
import {
  AlertTriangle,
  Eye,
  XCircle,
  BookOpen,
  ArrowRight,
  Timer,
  Brain,
  Target,
  CircleAlert
} from 'lucide-react';
import { useNavigate, useParams } from 'react-router-dom';

const MCQInstructions = () => {
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();
  const { interview_id } = useParams();
  const [hasAgreed, setHasAgreed] = useState(false);
  const instructions = [
    { icon: <CircleAlert />, text: "Candidates must answer the questions sequentially and cannot switch back to previous questions once they have moved on" },
    { icon: <XCircle />, text: "There is no Negative Marking" },
    { icon: <AlertTriangle />, text: "Don't try to sneak out of the tab, you will get 2 warnings, after that test will be submitted automatically" },
    { icon: <Timer />, text: "The test will automatically submit when the time is up" },
    { icon: <Eye />, text: "Read each question carefully before answering" },
    { icon: <BookOpen />, text: "No external help or reference materials are allowed during the test" }
  ];

  const features = [
    {
      icon: <Brain className="w-8 h-8 text-pink-500 mx-auto mb-2" />,
      title: "Test Your Knowledge",
      description: "Challenge yourself with carefully curated questions"
    },
    {
      icon: <Target className="w-8 h-8 text-pink-500 mx-auto mb-2" />,
      title: "Clear Objectives",
      description: "Each question is designed to assess specific skills"
    },
  ];

  return (
    <div className="min-h-screen flex ">
      {/* Left Section */}
      <div className="w-5/12 p-8 flex flex-col justify-center items-center text-white">
        <div className="max-w-lg space-y-12">
          <div className="text-center mb-12">
            <h1 className="text-4xl font-bold mb-6 bg-gradient-to-r from-[#69007f] to-[#fc0987] text-transparent bg-clip-text">Ready to Show Your Skills?</h1>
            <p className="text-lg opacity-90 text-gray-100">Take out a quick and interactive MCQ assessment to demonstrate your expertise</p>
          </div>

          <div className="grid grid-cols-2 gap-8">
            {features.map((feature, index) => (
              <div
                key={index}
                className="p-4 glass-effect rounded-lg transform hover:scale-105 transition-all duration-300"
              >
                <div>
                  {feature.icon}
                </div>
                <h3 className="text-lg font-semibold mb-2 text-gray-100 text-center">{feature.title}</h3>
                <p className="text-sm text-gray-300 text-center">{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Right Section - Instructions */}
      <div className="w-7/12 p-4">
        <div className="max-w-2xl mx-auto">
          <div className="glass-effect backdrop-blur-lg rounded-2xl p-8 shadow-xl">
            <h2 className="text-3xl font-bold text-center mb-8 bg-gradient-to-r from-[#69007f] to-[#fc0987] text-transparent bg-clip-text">
              Test Instructions
            </h2>

            <div className="space-y-4">
              {instructions.map((instruction, index) => (
                <div
                  key={index}
                  className="flex items-center gap-4 p-2 rounded-lg"
                >
                  <div className="w-6 h-6 text-pink-500 mt-1 flex-shrink-0">
                    {instruction.icon}
                  </div>
                  <p className="text-gray-300">
                    {instruction.text}
                  </p>
                </div>
              ))}
            </div>

            <div className="mt-6 flex items-center gap-2 justify-center">
              <input
                type="checkbox"
                id="agreement"
                checked={hasAgreed}
                onChange={(e) => setHasAgreed(e.target.checked)}
                className="w-4 h-4 text-pink-500 rounded focus:ring-pink-500"
              />
              <label htmlFor="agreement" className="text-gray-300 text-sm">
                I have read and agree to follow all the instructions
              </label>
            </div>

            <div className="mt-8 text-center">
              {/* <button
                onClick={() => navigate(`/candidate/mcqtest/${interview_id}`)}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                className="button group relative px-8 py-3 text-white font-semibold rounded-lg transform transition-all duration-300 hover:scale-105 hover:shadow-lg flex items-center gap-2"
              >
               <span className="flex gap-2">Proceed to Test <ArrowRight className={`w-5 h-5 transition-transform duration-300 ${isHovered ? 'translate-x-1' : ''}`} /></span>
              </button> */}

              {hasAgreed && (
                <button
                  onClick={() => navigate(`/candidate/mcqtest/${interview_id}`)}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                  className="button group relative px-8 py-3 text-white font-semibold rounded-lg transform transition-all duration-300 hover:scale-105 hover:shadow-lg flex items-center gap-2"
                >
                  <span className="flex gap-2">
                    Proceed to Test
                    <ArrowRight className={`w-5 h-5 transition-transform duration-300 ${isHovered ? 'translate-x-1' : ''}`} />
                  </span>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MCQInstructions;