import React, { useEffect, useState } from 'react';
import {Timer as Timing} from 'lucide-react'

const Timer = ({ timeLimit, onTimeUp }) => {
  const [timeLeft, setTimeLeft] = useState(timeLimit);

  useEffect(() => {
    setTimeLeft(timeLimit);
  }, [timeLimit]);

  useEffect(() => {
    if (timeLeft <= 0) {
      onTimeUp();
      return;
    }

    const intervalId = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft, onTimeUp]);

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${hours > 0 ? String(hours).padStart(2, '0') + ':' : ''}${String(mins).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
  };  

  return( 
    <>
<div className="fixed top-3 right-5 z-[1000] font-semibold bg-gradient-to-r from-[white] to-[white] bg-clip-text text-transparent flex items-center gap-2">
<Timing className='text-[white]'/>
{formatTime(timeLeft)}
</div>
</>
  );
};

export default Timer;
