import React, { useState, useEffect } from "react";
import axios from "axios";
import { Authenticator, Button } from "@aws-amplify/ui-react";
import host from "../../global";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import Loader from "../Loader";

const Actions = () => {
  const [topics, setTopics] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [questionsPerTopic, setQuestionsPerTopic] = useState({});
  const [isLoadingQuestions, setIsLoadingQuestions] = useState(false);
  const [setName, setSetName] = useState("");
  const [areTopicsLoading, setAreTopicsLoading] = useState(false);
  const accessToken = Cookies.get("accessToken");

  useEffect(() => {
    const fetchTopics = async () => {
      try {
        setAreTopicsLoading(true);
        const response = await axios.get(`${host}/get_mcq_topics`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        setTopics(response.data);
        setAreTopicsLoading(false);
      } catch (error) {
        if (error.response) {
          const status = error.response.status;
          const detail = error.response.data.detail;
          if (status === 401) {
            await new Promise((resolve) => {
              Cookies.remove("accessToken");
              resolve();
            });
            toast.error(`Unauthorized: ${detail}`);
          } else if (status === 404) {
            toast.warning(`Not Found: ${detail}`);
          } else {
            toast.error(`Error: ${detail}`);
          }
        } else {
          toast.error("An unexpected error occurred. Please try again.");
        }
      }
    };
    fetchTopics();
  }, [accessToken]);

  const handleInputChange = (topic, value) => {
    setQuestionsPerTopic((prev) => ({
      ...prev,
      [topic]: parseInt(value, 10),
    }));
  };

  const handleSetChange = (e) => {
    setSetName(e.target.value);
  };

  const handleSaveSet = async () => {
    try {
      const questionsDict = {};
      questions.forEach((question, index) => {
        questionsDict[index.toString()] = question;
      });

      const payload = {
        data: questionsDict,
      };

      const encodedSetName = encodeURIComponent(setName);

      const res = await axios.post(
        `${host}/create_mcq_set?setName=${encodedSetName}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (res.data.status === "success") {
        toast.success("Data Inserted");
        setSetName("");
        window.location.reload();
      }
    } catch (e) {
      console.error("Error details:", e.response?.data);
      toast.error("Error saving set");
    }
  };

  const handleFetchQuestions = async () => {
    try {
      setIsLoadingQuestions(true);
      const topicCounts = Object.entries(questionsPerTopic)
        .filter(([topic, count]) => count > 0)
        .map(([topic, count]) => ({ topic, count }));

      const response = await axios.post(`${host}/fetchmcq`, topicCounts, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      setQuestions(response.data);
    } catch (error) {
      console.error("Error fetching questions", error);
      toast.error("Error fetching questions");
    } finally {
        setIsLoadingQuestions(false);
    }
  };

  return (
    <Authenticator>
      <div>
        {areTopicsLoading ? (
          <Loader />
        ) : (
          <div className="flex flex-col md:flex-row justify-center gap-8 p-10">
            {/* Create MCQ's with AI */}
            <div className="flex flex-col w-full lg:w-[30%] h-[calc(100vh-120px)]">
              <h1 className="text-xl font-bold text-white mb-7 text-left">
                Create MCQ's with AI
              </h1>
              <div className="flex flex-col gap-5 m-2 overflow-y-auto">
                {topics.map((topic) => (
                  <div
                    key={topic}
                    className="flex items-center justify-between space-y-2"
                  >
                    <div className="text-md text-white">{topic}</div>
                    <input
                      type="number"
                      min="0"
                      max="10"
                      onChange={(e) => handleInputChange(topic, e.target.value)}
                      className="text-white glass-effect border border-1 rounded p-2 w-16 appearance-none"
                      aria-label={`Number of Questions for ${topic}`}
                    />
                  </div>
                ))}
              </div>
              <button
                class="button mx-auto mt-2.5 bg-white/10 backdrop-blur-sm rounded px-5 py-2.5 text-black shadow-lg font-normal text-base w-auto max-w-[300px] box-border"
                onClick={handleFetchQuestions}
              >
                <span>Fetch Questions</span>
              </button>
              <div className="mt-6 text-center">
                {questions.length > 0 && (
                  <div className="saveAsSet mb-4 flex items-center justify-center space-x-2">
                    <label className="mr-2 whitespace-nowrap text-md text-white">
                      Save As Set:
                    </label>
                    <input
                      placeholder="Set Name"
                      value={setName}
                      onChange={handleSetChange}
                      className="text-white glass-effect border border-gray-300 rounded p-2 w-40 text-md"
                    />
                    <button
                      onClick={handleSaveSet}
                      className="button text-md py-2 px-4 rounded hover:font-semibold"
                    >
                      <span>Save</span>
                    </button>
                  </div>
                )}
              </div>
            </div>
            {/* Question Box */}
            {isLoadingQuestions ? (
              <div className="flex flex-col w-full lg:w-[70%] border border-gray-300 rounded-2xl p-4 h-[calc(100vh-120px)]">
                <h3 className="text-md font-semibold text-gray-300 mb-2 text-left">
                  Questions
                </h3>
                <div className="animate-pulse overflow-y-auto flex-grow">
                  {[1, 2, 3].map((i) => (
                    <div key={i} className="mb-8 mt-4">
                      <div className="h-6 bg-gray-400 rounded w-3/4 mb-4"></div>
                      {[1, 2, 3, 4].map((j) => (
                        <div
                          key={j}
                          className="h-4 bg-gray-300 rounded w-1/2 mb-2 ml-4"
                        ></div>
                      ))}
                      <div className="border-b border-gray-100 mt-4"></div>
                    </div>
                  ))}
                </div>
              </div>
            ) : questions.length > 0 ? (
              <div className="flex flex-col w-full lg:w-[70%] border border-gray-300 rounded-2xl p-4 h-[calc(100vh-120px)]">
                <h3 className="text-md font-semibold text-gray-300 mb-2 text-left">
                  Questions
                </h3>
                <div className="overflow-y-auto flex-grow">
                  {questions.map((question, index) => (
                    <div key={index} className="question rounded">
                      <h3 className="text-base font-medium text-gray-300 text-left mt-4 mb-2">
                        {Number(index) + 1}.{" "}
                        {question.question}
                      </h3>
                      <ul className="list-none text-gray-300 text-base">
                        <li>a. {question.option1}</li>
                        <li>b. {question.option2}</li>
                        <li>c. {question.option3}</li>
                        <li>d. {question.option4}</li>
                      </ul>
                      <div className="border-b border-gray-300 mt-4"></div>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div className="flex flex-col w-full lg:w-[70%] border border-gray-300 rounded-2xl p-4 h-[calc(100vh-120px)]">
                <h3 className="text-md font-semibold text-gray-300 mb-2 text-left">
                  Questions
                </h3>
                {/* <img
                  className="mx-auto w-1/2 h-auto"
                  src="/fetch-question.jpg"
                  alt="Question"
                /> */}
                <p className="mt-10 text-center text-xs text-gray-400">
                  Feels empty! Select or Create a Set for questions to appear.
                </p>
              </div>
            )}
          </div>
        )}
      </div>
    </Authenticator>
  );
};

export default Actions;
