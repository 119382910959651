import React, { useState, useEffect } from "react";
import "../../css/HRPortal.css";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import host from "../../global";
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Dashboard = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [interviews, setInterviews] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const accessToken = Cookies.get('accessToken');
  const MAX_INTERVIEWS = 6;

  function convertToISO(date) {
    const timezoneOffset = date.getTimezoneOffset() * 60000;
    const localISOTime = new Date(
      date.getTime() - timezoneOffset
    ).toISOString();
    return localISOTime.slice(0, -1);
  }

  const convertToLocalTime = (utcTimeString) => {
    const utcDate = new Date(utcTimeString);
    const localTime = new Intl.DateTimeFormat('default', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    }).format(utcDate);
    return localTime;
  };

  const handleDateChange = async (date) => {
    setSelectedDate(date);
  };

  const InterviewCard = ({ interview }) => (
    <div className="py-[10px] flex justify-between items-center rounded-2xl shadow-sm cursor-pointer" onClick={() => navigate(`/hr/evaluationpage/${interview.candidate_id}`)}> 
      <div className="flex flex-col w-[150px]">
        <p 
          className="text-md font-semibold text-gray-300 truncate" 
          title={interview.candidate_name || "Unknown"}
        >
          {interview.candidate_name || "Unknown"}
        </p>
        <p 
          className="text-sm text-gray-400 truncate" 
          title={interview.job_role || "Unknown"}
        >
          {interview.job_role || "Unknown"}
        </p>
      </div>
      <div className="flex items-center">
        <div className="h-12 w-[1px] bg-gray-300 mx-3"></div>
        <p className="text-sm text-gray-400 font-semibold">
          {convertToLocalTime(interview.interview_time)}
        </p>
      </div>
    </div>
  );

  const renderInterviewCards = () => {
    const displayInterviews = interviews.slice(0, MAX_INTERVIEWS);
    
    return (
      <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 py-[15px] w-full">
        {displayInterviews.map((interview, index) => (
          <div 
            key={index} 
            className="flex justify-center"
          >
            <InterviewCard interview={interview} />
          </div>
        ))}
      </div>
    );
  };

  useEffect(() => {
    const fetchScheduledInterviews = async () => {
      setLoading(true);
      const isoDate = convertToISO(selectedDate) + 'Z';
      try {
        const response = await fetch(
          `${host}/scheduled_interviews?date=${isoDate}`,
          {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${accessToken}`,
            },
          }
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setInterviews(data);
      } catch (error) {
        if (error.response) {
          const status = error.response.status;
          const detail = error.response.data.detail;
          if (status === 401) {
            await new Promise((resolve) => {
              Cookies.remove('accessToken');
              resolve();
            });
            toast.error(`Unauthorized: ${detail}`);
          } else if (status === 404) {
            toast.warning(`Not Found: ${detail}`);
          } else {
            toast.error(`Error: ${detail}`);
          }
        } else {
          toast.error('An unexpected error occurred. Please try again.');
        }
        setInterviews([]);
      } finally {
        setLoading(false);
      }
    };
    fetchScheduledInterviews();
  }, [selectedDate, accessToken]);
  
  return (
    <div className="p-8 h-full overflow-auto no-scrollbar">
      <div className="flex flex-row justify-start items-center mb-8 gap-10">
        <div
          className="flex flex-col justify-center items-center card cursor-pointer"
          onClick={() => navigate(`/hr/checkresume`)}
        >
          <div className="text">
            <img src="/headhunting.png" alt="Card Icon" className="w-16 h-auto max-w-xs mx-auto" />
            <p className="subtitle">Parse Single Resume</p>
          </div>
        </div>
        <div
          className="flex flex-col justify-center items-center card cursor-pointer"
          onClick={() => navigate(`/hr/BulkResume`)}
        >
          <div className="text">
            <img src="/profile.png" alt="Card Icon" className="w-16 h-auto max-w-xs mx-auto" />
            <p className="subtitle">Bulk Resume Upload</p>
          </div>
        </div>
      </div>
      {/* <div
        className="glass-metric-grid"
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(230px, 1fr))",
          gap: "1rem",
          marginTop: "2rem",
          padding: "20px",
        }}
      >
        {["Total Test Submissions", "Passed Candidates", "Rejected Candidates", "Shortlisted Candidates"].map(
          (title, index) => (
            <Card
              key={index}
              style={{
                backdropFilter: "blur(10px)",
                background: "rgba(255, 255, 255, 0.2)",
                borderRadius: "16px",
              }}
            >
              <CardHeader
                avatar={<BusinessCenterRoundedIcon />}
                title={<Typography>{title}</Typography>}
              />
              <CardContent>
                <Typography variant="h4" style={{ textAlign: "center" }}>
                  {"0" + (index + 1)}
                </Typography>
              </CardContent>
            </Card>
          )
        )}
      </div> */}
      <div className="cardPattern flex flex-col gap-8 p-4 md:p-6">
        <h3 className="mt-2 font-semibold text-gray-300 text-lg ml-4">Interviews Scheduled</h3>
        <div className="flex flex-col lg:flex-row gap-2">
          <div className="w-full lg:w-[35%] flex justify-start">
            <Calendar 
              onChange={handleDateChange} 
              value={selectedDate} 
              className="w-full max-w-[250px] border-1"
            />
          </div>

          <div className="pl-2 w-full lg:w-[65%] flex flex-col">
            <div className="flex-grow">
            {loading ? (
                <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 py-[15px] w-full">
                  {[...Array(6)].map((_, index) => (
                    <div className="p-4 rounded-2xl shadow-sm animate-pulse">
                    <div className="flex justify-between items-center">
                      <div className="flex flex-col space-y-2 w-2/3">
                        <div className="h-4 bg-gray-500 rounded w-3/4"></div>
                        <div className="h-3 bg-gray-500 rounded w-1/2"></div>
                      </div>
                      <div className="h-8 w-[1px] bg-gray-500 mx-3"></div>
                      <div className="h-3 bg-gray-500 rounded w-1/4"></div>
                    </div>
                  </div>
                  ))}
                </div>
              ) : interviews.length > 0 ? (
                renderInterviewCards()
              ) : (
                <p className="text-center text-gray-500">No interviews scheduled</p>
              )}
            </div>
            
            {interviews.length > MAX_INTERVIEWS && (
              <div className="flex justify-center mt-4">
                <button 
                  onClick={() => navigate('/hr/interviews')}
                  className="button"
                >
                  <span>View All Interviews</span>
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

    </div>
  );
};
export default Dashboard;