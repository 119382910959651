import React, { useState, useEffect, useRef } from 'react';
import { Card, CardContent, Typography, IconButton, CircularProgress } from '@mui/material'; 
import { GetApp } from '@mui/icons-material';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import host from '../../global';
import Cookies from 'js-cookie'
import { toast } from 'react-toastify';

export default function MCQTestReport({ name, mcq_percent }) {
  const { interview_id } = useParams();
  const [mcqTestResultData, setMCQTestResultData] = useState({});
  const [loading, setLoading] = useState(true);
  const reportRef = useRef();
  const accessToken = Cookies.get("accessToken")

  useEffect(() => {
    const displayMCQTestResult = async () => {
      try {
        const response = await axios.get(`${host}/get_mcqs_test_result`,{
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            interview_id: interview_id,
          },
        });
        setMCQTestResultData(response.data);
        setLoading(false);
      } catch (error) {
        if (error.response) {
          const status = error.response.status;
          const detail = error.response.data.detail;
          if (status === 401) {
              await new Promise((resolve) => {
                Cookies.remove('accessToken');
                resolve();
              });
              toast.error(`Unauthorized: ${detail}`);
          } else if (status === 404) {
              toast.warning(`Not Found: ${detail}`);
          } else {
              toast.error(`Error: ${detail}`);
          }
      } else {
          toast.error('An unexpected error occurred. Please try again.');
      }
        setLoading(false);
      }
    };
    displayMCQTestResult();
  }, [interview_id,accessToken]);

  const handleDownloadPDF = () => {
    html2canvas(reportRef.current, {
      onclone: (document) => {
        const elementsToHide = document.querySelectorAll('.no-print');
        elementsToHide.forEach((el) => (el.style.display = 'none'));
      }
    }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF();
      
      const marginTop = 10;  
      const marginLeft = 10;  
      const marginBottom = 10; 
      const marginRight = 10; 
  
      const imgWidth = pdf.internal.pageSize.width - marginLeft - marginRight; 
      const pageHeight = pdf.internal.pageSize.height;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
  
      let heightLeft = imgHeight;
  
      let position = marginTop; 
      pdf.addImage(imgData, 'PNG', marginLeft, position, imgWidth, imgHeight);
      heightLeft -= (pageHeight - marginBottom); 
  
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight + marginTop; 
        pdf.addPage();
        pdf.addImage(imgData, 'PNG', marginLeft, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      pdf.save(`${name}_MCQ_Test_Report.pdf`);
    });
  };

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div style={{ margin: '0 auto', padding: '16px' }} ref={reportRef}>
      <Card elevation={3}>
        <CardContent
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            paddingBottom: '5px',
            borderBottom: '1px solid #e0e0e0',
          }}
        >
<Typography variant="h5" fontWeight="bold" color="white">
  MCQ Test Report -  
  <Typography variant="h6" fontWeight="normal" color="white" style={{ display: 'inline', marginLeft:'5px' }}>
    {name}
  </Typography>
</Typography>

          <IconButton onClick={handleDownloadPDF} className="no-print">
            <GetApp style={{color:"white"}}/>
          </IconButton>
        </CardContent>
        <CardContent>
          <div style={{ display: 'flex', justifyContent: 'space-evenly', borderBottom: '1px solid #e0e0e0' }}>
            <Typography variant="body1" color="white">
              Percent Score: {mcq_percent || '0'}%
            </Typography>
            <Typography variant="body1" color="white">
              Time span: {mcqTestResultData.time_taken || '0'}s
            </Typography>
            <Typography variant="body1" color="white">
              {mcqTestResultData.startDateTime || "NA"}
            </Typography>
          </div>

          {mcqTestResultData.mcqs &&
            Object.keys(mcqTestResultData.mcqs).map((key, index) => {
              const questionData = mcqTestResultData.mcqs[key];
              return (
                <div key={index} style={{ marginTop: '16px',paddingBottom:'10px',borderBottom: '1px solid #A09F9F' }}>
                  <Typography variant="subtitle1" fontWeight="bold" color="white">
                  <pre
                      style={{
                        overflowWrap: 'break-word',
                        whiteSpace: 'pre-wrap',
                        wordWrap: 'break-word',
                        margin: 0,
                        fontFamily:'Poppins'
                      }}
                    >
                      {index + 1}. {questionData.question}
                    </pre>                  </Typography>
                  <div style={{ marginLeft: '16px', marginTop: '8px' }}>
                    {[questionData.option1, questionData.option2, questionData.option3, questionData.option4].map(
                      (option, optIndex) => {
                        const isCorrect = option === questionData.answer;
                        const isGivenAnswer = option === questionData.given_answer;

                        return (
                          <div
                            key={optIndex}
                            style={{
                              padding: '8px',
                              borderRadius: '4px',
                              backgroundColor: isGivenAnswer ? (isCorrect ? '#d4edda' : '#f8d7da') : 'rgba(229, 229, 229, 0.5)',
                              border: isCorrect
                                ? '1px solid #c3e6cb'
                                : isGivenAnswer
                                ? '1px solid #f5c6cb'
                                : '1px solid #dee2e6',
                              marginBottom: '4px',
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              overflowWrap: 'break-word'
                            }}
                          >
                            {option}
                            {isGivenAnswer && (isCorrect ? <DoneIcon style={{ color: 'green' }} /> : <CloseIcon style={{ color: 'red' }} />)}
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              );
            })}
        </CardContent>
      </Card>
    </div>
  );
}
