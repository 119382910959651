import React, { useState, useEffect } from 'react';
import { Mail, Sparkles, Calendar, CheckCircle } from 'lucide-react';

const AiInterviewScheduler = () => {
  const [progress, setProgress] = useState(0);
  const [phase, setPhase] = useState('analyzing');

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress(prev => {
        if (prev < 100) {
          const newProgress = prev + 0.5;
          if (newProgress > 20) setPhase('preparing');
          if (newProgress > 50) setPhase('sending');
          if (newProgress > 85) setPhase('finalizing');
          if (newProgress >= 100) setPhase('completed');
          return newProgress;
        }
        return prev;
      });
    }, 30);
    return () => clearInterval(timer);
  }, []);

  const MailIcon = () => (
    <div className="relative">
      <div className="absolute -inset-2 bg-blue-100/50 rounded-full blur-md" />
      <Mail 
        size={40} 
        className="text-blue-600 relative z-10" 
        strokeWidth={1.5}
      />
      <Calendar 
        size={20} 
        className="text-blue-500 absolute -right-2 -bottom-2 z-20"
      />
      <Sparkles 
        size={20} 
        className="text-blue-400 absolute -right-2 -top-2 z-20" 
      />
    </div>
  );

  return (
    <div className="w-full h-96 rounded-lg relative flex items-center justify-center overflow-hidden">
      {/* Animation Path */}
      <div className="absolute w-full h-40 top-1/2 -translate-y-1/2 overflow-hidden">
        <div className="relative h-full">
          {/* Enhanced data flow effect */}
          <div className="absolute h-[2px] w-full top-1/2 -translate-y-1/2">
            <div className="h-full bg-gradient-to-r from-blue-100 via-blue-400/40 to-blue-100" />
            <div className="absolute -top-1 w-full">
              {Array.from({ length: 8 }).map((_, i) => (
                <div
                  key={i}
                  className="absolute h-2 w-12 bg-gradient-to-r from-blue-200/30 to-blue-300/20 rounded-full"
                  style={{
                    left: `${(i * 15) + ((progress * 1.5) % 15)}%`,
                    animation: 'pulse 2s infinite',
                    animationDelay: `${i * 0.2}s`
                  }}
                />
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Flying email animation */}
      <div 
        className="absolute"
        style={{
          left: `${Math.min(Math.max(progress, 0), 100)}%`,
          top: '50%',
          transform: `translateY(-50%) ${phase === 'sending' ? 'rotate(-8deg)' : 'rotate(0deg)'}`,
          transition: 'all 0.8s cubic-bezier(0.4, 0, 0.2, 1)'
        }}
      >
        <div className="relative">
          <div className="absolute -inset-4 bg-blue-100/40 rounded-full blur-xl" />
          <div className="relative transform hover:scale-105 transition-transform duration-300">
            <MailIcon />
          </div>
        </div>
      </div>

      {/* Status display */}
      <div className="absolute bottom-8 left-1/2 -translate-x-1/2 flex flex-col items-center w-full px-4">
        <div className="flex items-center gap-2 mb-6">
          <span className="text-gray-300 text-lg font-medium">
            {phase === 'analyzing' && "Analyzing candidate profile..."}
            {phase === 'preparing' && "Preparing personalized interview invitation..."}
            {phase === 'sending' && "Sending AI-powered interview scheduler..."}
            {phase === 'finalizing' && "Finalizing secure delivery..."}
            {phase === 'completed' && (
              <div className="flex items-center gap-2 text-green-500">
                <CheckCircle size={24} />
                <span>Interview invitation delivered successfully!</span>
              </div>
            )}
          </span>
        </div>

        {/* Enhanced progress bar */}
        <div className="w-full max-w-lg relative px-8">
          <div className="h-2 bg-gray-100 rounded-full overflow-hidden shadow-inner">
            <div 
              className="h-full rounded-full transition-all duration-300 bg-gradient-to-r from-blue-500 to-blue-600 relative"
              style={{ width: `${progress}%` }}
            >
              <div className="absolute inset-0 bg-gradient-to-r from-transparent via-white/30 to-transparent animate-shimmer" />
            </div>
          </div>
          <span className="absolute right-0 top-1/2 -translate-y-1/2 text-sm text-gray-500 font-medium">
            {Math.round(progress)}%
          </span>
        </div>
      </div>
    </div>
  );
};

// Add shimmer animation
const style = document.createElement('style');
style.textContent = `
  @keyframes shimmer {
    0% { transform: translateX(-100%); }
    100% { transform: translateX(100%); }
  }
  .animate-shimmer {
    animation: shimmer 2s infinite linear;
  }
`;
document.head.appendChild(style);

export default AiInterviewScheduler;