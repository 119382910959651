import React, { useState, useEffect } from "react";
import axios from "axios";
import host from "../../global";
import { Authenticator } from "@aws-amplify/ui-react";
import EmailLoader from "../EmailSentLoader";
import { Upload, FileText, Scan, CheckCircle2 } from "lucide-react";
import TimeFrameSelector from "../TimeFrameSelector";
import {
  CheckCircle,
  AlertCircle,
  ChevronRight,
  Mail,
  User,
} from "lucide-react";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../Loader";
import {
  Skeleton,
} from "@mui/material";
const CheckResumeOrgAdmin = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isEmailLoading, SetEmailLoading] = useState(false);
  const [jobPositions, setJobPositions] = useState({});
  const [customQuestions, setCustomQuestions] = useState([]);
  const [selectedJobPosition, setSelectedJobPosition] = useState("");
  const [selectedID, setSelectedID] = useState("");
  const [jobDescription, setJobDescription] = useState("");
  const [resume, setResume] = useState(null);
  const [responseReport, setResponseReport] = useState("");
  const [jobId, setJobId] = useState("");
  const [responseScore, setResponseScore] = useState(0);
  const [interviewID, setInterviewID] = useState("");
  const [resumeName, setResumeName] = useState("");
  const [name, setName] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [emailID, setEmailID] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [isConfirmClicked, setIsConfirmClicked] = useState(false);
  const [resumeContent, setResumeContent] = useState("");
  const [selectedInterviewType, setSelectedInterviewType] = useState("");
  const [interview_timelimit, setInterviewTimeLimit] = useState(0);
  const [selectedMCQSet, setSelectedMCQSet] = useState("");
  const [codingJudgingCriteria, setCodingJudgingCriteria] = useState([]);
  const [selectedCodingSet, setSelectedCodingSet] = useState("");
  const [resumeScoreThreshold,setResumeScoreThreshold] = useState(60)
  const [timeFrame, setTimeFrame] = useState({
    startDate: new Date(),
    endDate: new Date(new Date().setDate(new Date().getDate() + 7)),
  });
  const [MCQTimeLimit, setMCQTimeLimit] = useState(0);
  const [CodingTimeLimit, setCodingTimeLimit] = useState(0);
  const accessToken = Cookies.get("accessToken");
  const [isExpanded, setIsExpanded] = useState(false);
  const [isScanning, setIsScanning] = useState(false);
  const [scanProgress, setScanProgress] = useState(0);
  const [scanPhase, setScanPhase] = useState("");
  const scanPhases = [
    "Initializing AI Scanner...",
    "Analyzing Document Structure...",
    "Extracting Key Information...",
    "Processing Experience Data...",
    "Evaluating Skills Matrix...",
    "Finalizing Analysis...",
  ];
  const toggleExpand = () => {
    setIsExpanded((prev) => !prev);
  };
  useEffect(() => {
    if (isScanning) {
      const interval = setInterval(() => {
        setScanProgress((prev) => {
          if (prev >= 100) {
            clearInterval(interval);
            return 100;
          }
          return prev + 0.5;
        });
      }, 50);
      return () => clearInterval(interval);
    }
  }, [isScanning]);
  useEffect(() => {
    if (isScanning) {
      const phaseIndex = Math.floor((scanProgress / 100) * scanPhases.length);
      setScanPhase(scanPhases[Math.min(phaseIndex, scanPhases.length - 1)]);
    }
  }, [scanProgress, isScanning]);
  useEffect(() => {
    const fetchJobPositions = async () => {
      try {
        const response = await axios.get(`${host}/getjdpositions`, {
          headers: {
            Authorization: `Bearer ${accessToken.toString()}`,
          },
        });
        setJobPositions(response.data);
      } catch (error) {
        if (error.response) {
          const status = error.response.status;
          const detail = error.response.data.detail;
          if (status === 401) {
            await new Promise((resolve) => {
              Cookies.remove("accessToken");
              resolve();
            });
            toast.error(`Unauthorized: ${detail}`);
          } else if (status === 404) {
            toast.warning(`Not Found: ${detail}`);
          } else {
            toast.error(`Error: ${detail}`);
          }
        } else {
          toast.error("An unexpected error occurred. Please try again.");
        }
      }
    };
    fetchJobPositions();
  }, [accessToken]);
  const handleEmailChange = (e) => {
    setEmailID(e.target.value);
  };
  const handleNameChange = (e) => {
    setName(e.target.value);
  };
  const handleJobPositionChange = async (e) => {
    const selectedPosition = jobPositions[e.target.value];
    setSelectedID(e.target.value);
    if (selectedPosition !== "") {
      setSelectedJobPosition(selectedPosition.job_role);
      setJobDescription("");
      setResponseReport("");
      setResponseScore(0);
      setInterviewID("");
      setIsSubmitting(false);
      setEmailID("");
      setIsVisible(false);
      setIsCheckboxChecked(false);
      setIsConfirmClicked(false);
      setResumeContent("");
      setIsLoading(true);
      try {
        const response = await axios.get(`${host}/getjd`, {
          headers: {
            Authorization: `Bearer ${accessToken.toString()}`,
          },
          params: {
            jd_id: e.target.value,
          },
        });
        if (response) {
          setJobId(response.data.id); 
          setJobDescription(response.data.description);
          setCodingTimeLimit(response.data.coding_time_limit);
          setMCQTimeLimit(response.data.mcq_time_limit);
          setInterviewTimeLimit(response.data.interview_timelimit);
          setSelectedInterviewType(response.data.interview_type);
          setSelectedCodingSet(response.data.Coding_set);
          setSelectedMCQSet(response.data.mcq_set);
          setCodingJudgingCriteria(response.data.Coding_Judge_Criteria);
          setCustomQuestions(response.data.customQuestions || []);
        }
        
      } catch (error) {
        if (error.response) {
          const status = error.response.status;
          const detail = error.response.data.detail;
          if (status === 401) {
            await new Promise((resolve) => {
              Cookies.remove("accessToken");
              resolve();
            });
            toast.error(`Unauthorized: ${detail}`);
          } else if (status === 404) {
            toast.warning(`Not Found: ${detail}`);
          } else {
            toast.error(`Error: ${detail}`);
          }
        } else {
          toast.error("An unexpected error occurred. Please try again.");
        }
        setJobDescription("");
      }
      finally {
        setIsLoading

          (false);
      }
    }
  };
  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files[0];
    if (
      ![
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ].includes(droppedFile.type)
    ) {
      toast.error("Only PDF or document files are allowed.");
      return;
    }
    setResume(droppedFile);
    setResumeName(droppedFile.name);
    handleFileChange({ target: { files: [droppedFile] } });
  };
  const handleDragOver = (event) => {
    event.preventDefault();
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (
      file &&
      ![
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ].includes(file.type)
    ) {
      toast.error("Only PDF or document files are allowed.");
      return;
    }
    setResume(file);
    setResumeName(file.name);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsScanning(true);
    document.documentElement.scrollTop = 0;
    const shuffledQuestions = customQuestions.sort(() => Math.random() - 0.5);
    setCustomQuestions(shuffledQuestions);
    if (!selectedJobPosition || !jobDescription|| !jobId) {
      setIsScanning(false);
      toast.error("Please select a job position and load the job description.");
      return;
    }
    if (!resume) {
      setIsScanning(false);
      toast.error("Please upload a resume.");
      return;
    }
    const formData = new FormData();
    formData.append("interview_type", selectedInterviewType);
    formData.append("time_limit", interview_timelimit);
    formData.append("job_id", jobId );
    formData.append("job_role", selectedJobPosition);
    formData.append("job_description", JSON.stringify(jobDescription));
    if (customQuestions.length > 0) {
      formData.append("custom_questions", JSON.stringify(customQuestions));
    }
    formData.append("file", resume);
    try {
      const response = await axios.post(`${host}/parse_resume`, formData, {
        headers: {
          Authorization: `Bearer ${accessToken.toString()}`,
        },
      });
      if (response.data.Error) {
        toast.error(response.data.Error);
        return;
      }
      setResponseReport(response.data.report);
      setResponseScore(response.data.score);
      setEmailID(response.data.email);
      setName(response.data.name);
      setInterviewID(response.data.int_id);
      setResumeContent(response.data.resume_content);
      setResumeScoreThreshold(response.data.resume_score_threshold)
      setIsScanning(false);
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        const detail = error.response.data.detail;
        if (status === 401) {
          await new Promise((resolve) => {
            Cookies.remove("accessToken");
            resolve();
          });
          toast.error(`Unauthorized: ${detail}`);
        } else if (status === 404) {
          toast.warning(`Not Found: ${detail}`);
        } else {
          toast.error(`Error: ${detail}`);
        }
      } else {
        toast.error("An unexpected error occurred. Please try again.");
      }
    } finally {
      setIsScanning(false);
    }
  };
  if (isSubmitting) {
    return (
      <Loader />
    );
  }
  const handleConfirm = async () => {
    setIsConfirmClicked(true);
    setIsCheckboxChecked(true);
    setIsVisible(!isVisible);
  };
  const sendNotification = async (e) => {
    e.preventDefault();
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailID) {
      toast.warn("Please enter an email ID");
      return;
    }
    if (!name) {
      toast.warn("Please enter a Name");
      return;
    }
    if (!emailPattern.test(emailID)) {
      toast.error("Please enter a valid email ID");
      return;
    }
    const formData = new FormData();
    formData.append("interview_id", interviewID);
    formData.append("name", name);
    formData.append("candidate_email", emailID);
    formData.append("mcq_set", selectedMCQSet);
    formData.append("Coding_Judge_Criteria", codingJudgingCriteria);
    formData.append("coding_set", selectedCodingSet);
    formData.append("mcq_time_limit", MCQTimeLimit);
    formData.append("coding_time_limit", CodingTimeLimit);
    formData.append("start_date", timeFrame.startDate.toISOString());
    formData.append("end_date", timeFrame.endDate.toISOString());
    formData.append("job_id", jobId)
    if (responseScore < resumeScoreThreshold) {
      formData.append("job_role", selectedJobPosition);
      formData.append("job_description", JSON.stringify(jobDescription));
      if (customQuestions.length > 0) {
        formData.append("custom_questions", JSON.stringify(customQuestions));
      }
      formData.append("resume_content", resumeContent);
      formData.append("score", responseScore);
      formData.append("interview_type", selectedInterviewType);
      formData.append("interview_timelimit", interview_timelimit);
    }
    SetEmailLoading(true);
    try {
      await axios.post(`${host}/store_candidate_data`, formData, {
        headers: {
          Authorization: `Bearer ${accessToken.toString()}`,
          "Content-Type": "multipart/form-data",
        },
      });
      toast.success("Candidate data stored successfully.");
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      console.log(error)
      if (error.response) {
        const status = error.response.status;
        const detail = error.response.data.detail;
        if (status === 401) {
          await new Promise((resolve) => {
            Cookies.remove("accessToken");
            resolve();
          });
          toast.error(`Unauthorized: ${detail}`);
        } else if (status === 404) {
          toast.warning(`Not Found: ${detail}`);
        } else {
          toast.error(`Error: ${detail}`);
        }
      } else {
        toast.error("An unexpected error occurred. Please try again.");
      }
    } finally {
      SetEmailLoading(false);
    }
  };
  const handleCheckboxChange = (e) => {
    setIsCheckboxChecked(e.target.checked);
    if (!e.target.checked) {
      setIsVisible(false);
    }
  };
  const handleTimeFrameChange = (timeFrame) => {
    setTimeFrame(timeFrame);
  };
  const formatJobDescription = (data) => {
    let result = "";
    if (data.minExp !== null && data.minExp !== undefined && data.maxExp !== null && data.maxExp !== undefined) {
      result += `Experience Range: ${data.minExp} - ${data.maxExp}\n\n`;
    }
    const Experience_Level = `${(data.minExp + data.maxExp)/2}`
    const isMediumSkillsRequired = Experience_Level < 4;
    if (data.Required_Skills) {
      if (data.Required_Skills.Hard && data.Required_Skills.Hard.length > 0) {
        result += `Required Skills - Hard:\n`;
        data.Required_Skills.Hard.forEach((item, index) => {
          result += `  ${index + 1}. ${item.join(", ")}\n`;
        });
        result += "\n";
      }
      if (
        data.Required_Skills.Medium &&
        Array.isArray(data.Required_Skills.Medium) &&
        data.Required_Skills.Medium.length > 0
      ) {
        if (isMediumSkillsRequired) {
          result += `Required Skills - Medium:\n`;
          data.Required_Skills.Medium.forEach((item, index) => {
            result += `  ${index + 1}. ${item.join(", ")}\n`;
          });
          result += "\n";
        }
      }
    }
    if (data.Required_Skills) {
      if (data.Required_Skills.Easy && data.Required_Skills.Easy.length > 0) {
        result += `Optional Skills - Easy:\n`;
        data.Required_Skills.Easy.forEach((item, index) => {
          result += `  ${index + 1}. ${item.join(", ")}\n`;
        });
        result += "\n";
      }
      if (
        data.Required_Skills.Medium &&
        !isMediumSkillsRequired &&
        data.Required_Skills.Medium.length > 0
      ) {
        result += `Optional Skills - Medium:\n`;
        data.Required_Skills.Medium.forEach((item, index) => {
          result += `  ${index + 1}. ${item.join(", ")}\n`;
        });
        result += "\n";
      }
    }
    if (data.Roles_and_Responsibilities && data.Roles_and_Responsibilities.length > 0) {
      result += `Roles and Responsibilities:\n`;
      data.Roles_and_Responsibilities.forEach((item, index) => {
        result += `  - ${item}\n`;
      });
      result += "\n";
    }
    if (data.GoodtoHave && data.GoodtoHave.length > 0) {
      result += `Good to Have:\n  - ${data.GoodtoHave.join(", ")}\n\n`;
    }
    if (data.Tools_and_Technologies && data.Tools_and_Technologies.length > 0) {
      result += `Tools and Technologies:\n`;
      data.Tools_and_Technologies.forEach((item, index) => {
        result += `  - ${item}\n`;
      });
      result += "\n";
    }
    return result;
  };

  if (isEmailLoading) {
    return (
      <EmailLoader/>
    )};
  const formattedDescription = formatJobDescription(jobDescription);
  return (
    <Authenticator>
      <div className="min-h-screen">
          <div className="flex flex-col lg:flex-row gap-4 p-8">
            <div className="w-2/5">
                <div className="flex flex-col gap-8 p-4">
                <div className="space-y-9">
                    <FormControl fullWidth>
                      <InputLabel style={{ color: "rgba(216, 216, 216, 1)"}}>Select Job Position</InputLabel>
                      <Select
                        value={selectedID}
                        onChange={handleJobPositionChange}
                        className="glass-effect bg-white/30 backdrop-blur-sm rounded-xl"
                        style={{
                          fontFamily: "Poppins",
                          borderRadius: "8px",
                          color: "rgba(216, 216, 216, 1)",
                        }}
                        label="Select Job Position"
                        MenuProps={{
                          PaperProps: {
                            style: {
                              background:
                                "linear-gradient(233.57deg, rgba(34, 52, 97, 0.25) -5.01%, rgba(11, 10, 31, 0.05) 100%)",
                              opacity: 1,
                              backdropFilter: "blur(500px)",
                              color: "rgba(216, 216, 216, 1)",
                            },
                          },
                        }}
                        sx={{
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(229, 229, 229, 0.5)",
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(229, 229, 229, 0.75)",
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(229, 229, 229, 1)",
                          },
                          "& .MuiSvgIcon-root.MuiSelect-icon": {
                            color: "rgba(216, 216, 216, 1) !important",
                          },
                        }}
                      >
                      {Object.keys(jobPositions).length > 0 ? (
                        Object.entries(jobPositions).map(([id, position]) => (
                          <MenuItem key={id} value={id}>
                            {position.job_role}
                          </MenuItem>
                        ))
                      ) : (
                        Array.from({ length: 5 }).map((_, index) => (
                          <MenuItem key={index} disabled>
                            <Skeleton variant="text" width="100%" />
                          </MenuItem>
                        ))
                      )}
                      </Select>
                    </FormControl>
                  </div>
                  {(isLoading || jobDescription) && (
                  <div className="flex flex-col gap-8 glass-effect p-4 rounded-xl">
                    <div className="space-y-3 w-full">
                      <div className="flex items-center justify-between">
                        <label className="block text-sm font-medium text-gray-300">
                          Job Description
                        </label>
                        {!isLoading && (
                          <button
                            onClick={toggleExpand}
                            className="button flex items-center gap-1"
                          >
                            <span className="flex gap-2 items-center">
                              {isExpanded ? "Show Less" : "Expand"}
                              <ChevronRight
                                className={`h-4 w-4 transition-transform duration-300 ${isExpanded ? "rotate-90" : ""}`}
                              />
                            </span>
                          </button>
                        )}
                      </div>
                      <div className="relative rounded-xl overflow-hidden">
                        {isLoading ? (
                          <div className="space-y-3 p-6 bg-gray-400/50 backdrop-blur-sm rounded-xl">
                            <Skeleton variant="text" width="10%" height={34} />
                            <Skeleton variant="text" width="20%" height={34} />
                            <Skeleton variant="text" width="90%" height={34} />
                            <Skeleton variant="text" width="90%" height={34} />
                            <Skeleton variant="text" width="90%" height={34} />
                          </div>
                        ) : (
                          <div className={`${isExpanded ? "h-auto" : "max-h-60 overflow-hidden"}`}>
                            <pre className="glass-effect text-gray-300 font-sans text-sm whitespace-pre-wrap break-words p-6 bg-gray-50/50 backdrop-blur-sm rounded-xl line-clamp-10">
                              {formattedDescription || "No job description available."}
                            </pre>
                            {`\n`}
                          </div>
                        )}
                      </div>
                    </div>
                  {customQuestions.length > 0 && (
                    <div className="space-y-3">
                      <label className="block text-sm font-medium text-gray-300">
                        Screening Questions
                      </label>
                      <div className="glass-effect border border-gray-200/50 rounded-2xl p-6 max-h-96 overflow-y-auto">
                        {customQuestions.map((question, index) => (
                          <div key={index} className="mb-6 last:mb-0">
                            <div className="flex gap-4">
                              <div className="flex-shrink-0 w-8 h-8 bg-blue-100 rounded-full flex items-center justify-center">
                                <span className="text-sm font-medium text-pink-500">
                                  {index + 1}
                                </span>
                              </div>
                              <p className="text-sm text-gray-300 leading-relaxed">
                                {question}
                              </p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  {jobDescription && (
                   <div className="flex justify-center w-full">
                   <button
                     onClick={handleSubmit}
                     className="w-48 button text-white px-6 py-3 rounded-full hover:scale-105 transition-transform flex items-center justify-center"
                   >
                     <span>Analyze Resume</span>
                   </button>
                 </div>
                  )}
                  </div>
                  )}
                </div>
            </div>
            <div className="flex flex-col gap-5 w-3/5 p-4">
            {!isScanning ? (
                    <div
                      className="glass-effect space-y-6 w-full relative rounded-xl p-4 cursor-pointer "
                      onClick={() =>
                        document.getElementById("fileInput")?.click()
                      }
                      onDrop={handleDrop}
                      onDragOver={handleDragOver}
                    >
                      {resume ? (
                        <div className="transform transition-all duration-500 ease-in-out">
                          <div className="flex items-center justify-center">
                          <img src={'/resumeuploaded.png'} className="w-16 h-16 text-white" />
                          </div>
                          <div className="space-y-4 mt-6">
                            <h3 className="text-2xl font-bold text-white text-center animate-fade-in">
                              Resume Successfully Uploaded!
                            </h3>
                            <div className="flex items-center justify-center space-x-2">
                              <FileText className="w-5 h-5 text-white" />
                              <p className="text-white font-medium">
                                {resumeName}
                              </p>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <>
                          <div className="flex items-center justify-center">
                            <img src={'/headhunting.png'} className="w-16 h-16 text-white" />
                            <input
                              id="fileInput"
                              type="file"
                              className="hidden"
                              onChange={handleFileChange}
                              accept=".pdf,.docx"
                            />
                          </div>
                          <div className="text-center space-y-4">
                            <p className="text-pink-500 font-medium">
                              {resumeName || "Upload Your Resume"}
                            </p>
                            <p className="text-sm text-gray-300 font-medium">
                              Supported formats: PDF, DOCX
                            </p>
                          </div>
                        </>
                      )}
                    </div>
                  ) : (
                    <div className="space-y-8 py-8 px-4 relative border-2 border-dashed border-gray-300 rounded-2xl">
                      <div className="flex flex-col items-center space-y-4">
                        <div className="relative w-24 h-24">
                          <div className="absolute inset-0 rounded-full border-4 border-teal-500/20" />
                          <div
                            className="absolute inset-0 rounded-full border-4 border-transparent border-t-teal-500"
                            style={{
                              animation: "spin 1s linear infinite",
                            }}
                          />
                          <FileText className="absolute inset-0 m-auto w-12 h-12 text-teal-500" />
                        </div>
                        <div className="text-center">
                          <h4 className="text-lg font-semibold text-gray-300 mb-2">
                            {scanPhase}
                          </h4>
                        </div>
                      </div>
                      <div className="w-full bg-gray-200 rounded-full h-2 overflow-hidden">
                        <div
                          className="h-full bg-gradient-to-r from-teal-500 to-green-500 transition-all duration-300"
                          style={{ width: `${scanProgress}%` }}
                        />
                      </div>
                      <div className="grid grid-cols-3 gap-4 text-xs text-gray-300">
                        {[
                          "Document Analysis",
                          "Data Extraction",
                          "AI Processing",
                        ].map((step, index) => (
                          <div
                            key={step}
                            className="flex items-center justify-center space-x-2"
                          >
                            {scanProgress >= (index + 1) * 33 ? (
                              <CheckCircle2 className="w-4 h-4 text-teal-500" />
                            ) : (
                              <div className="w-4 h-4 rounded-full border border-teal-300" />
                            )}
                            <span>{step}</span>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
            {responseReport && (
              <div className="w-full">
                <div className="glass-effect rounded-3xl shadow-xl p-4">
                  <div className="space-y-8">
                    <div className="flex gap-8 p-4">
                    <div className="text-center">
                      <div className="relative inline-flex">
                        <div
                          className={`w-32 h-32 rounded-full flex items-center justify-center
                                     ${responseScore >= resumeScoreThreshold
                              ? "bg-gradient-to-br from-green-400 to-green-600"
                              : "bg-gradient-to-br from-yellow-400 to-yellow-600"
                            }`}
                        >
                          <span className="text-4xl font-bold text-white">
                            {responseScore}%
                          </span>
                        </div>
                        <div className="absolute -right-2 -top-2">
                          {responseScore >= resumeScoreThreshold ? (
                            <CheckCircle className="w-8 h-8 text-green-500" />
                          ) : (
                            <AlertCircle className="w-8 h-8 text-yellow-500" />
                          )}
                        </div>
                      </div>
                      <h3 className="mt-4 text-xl font-semibold text-gray-300">
                        Match Score
                      </h3>
                    </div>
                    <div className="rounded-lg p-4">
                      <h4 className="text-sm font-medium text-slate-300 mb-2">
                        Analysis Report
                      </h4>
                      <p className="text-sm text-slate-200">{responseReport}</p>
                    </div>
                    </div>
                    {responseScore < resumeScoreThreshold && (
                      <div className="glass-effect flex items-start gap-4 p-6 bg-yellow-50/50 backdrop-blur-sm rounded-2xl border border-yellow-200/50">
                        <div className="flex-shrink-0">
                          <AlertCircle className="w-6 h-6 text-yellow-500" />
                        </div>
                        <div className="flex-1">
                          <h4 className="text-sm font-medium text-yellow-400 mb-2">
                            Low Match Score
                          </h4>
                          <div className="flex items-center gap-3">
                            <input
                              type="checkbox"
                              className="h-4 w-4 rounded border-yellow-300 text-blue-600 focus:ring-blue-500"
                              checked={isCheckboxChecked}
                              onChange={handleCheckboxChange}
                              disabled={isConfirmClicked}
                            />
                            <label className="text-sm text-yellow-400">
                              Proceed with interview despite low match score?
                            </label>
                          </div>
                        </div>
                      </div>
                    )}
                    {(responseScore >= resumeScoreThreshold || isCheckboxChecked) && (
                      <div className="space-y-6">
                        {responseScore < resumeScoreThreshold && !isConfirmClicked ? (
                          <button
                            className="button w-full bg-gradient-to-r from-blue-600 to-indigo-600 hover:from-blue-700 hover:to-indigo-700
                                     text-white py-4 px-6 rounded-2xl transition-all duration-300 font-medium text-sm
                                     shadow-lg hover:shadow-xl hover:scale-[1.02] transform"
                            onClick={handleConfirm}
                          >
                            <span>Confirm Interview Process</span>
                          </button>
                        ) : (
                          <div className="space-y-6">
                            <TimeFrameSelector
                              onDateRangeChange={handleTimeFrameChange}
                            />
                            {(responseScore >= resumeScoreThreshold || isCheckboxChecked) && (
                              <form className="space-y-4">
                                <div>
                                  <label className="block text-sm font-medium text-slate-300 mb-2">
                                    Candidate Details
                                  </label>
                                  <div className="flex gap-2 w-full">
                                    <div className="relative w-1/2">
                                      <Mail className="absolute left-3 top-1/2 -translate-y-1/2 h-5 w-5 text-slate-300" />
                                      <input
                                        value={emailID}
                                        type="email"
                                        placeholder="Email address"
                                        onChange={handleEmailChange}
                                        className="glass-effect text-gray-300 w-full pl-10 pr-4 py-2 bg-slate-50 border border-slate-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                      />
                                    </div>
                                    <div className="relative w-1/2">
                                      <User className="absolute left-3 top-1/2 -translate-y-1/2 h-5 w-5 text-slate-300" />
                                      <input
                                        value={name}
                                        type="text"
                                        placeholder="Full name"
                                        onChange={handleNameChange}
                                        className="glass-effect text-gray-300 w-full pl-10 pr-4 py-2 bg-slate-50 border border-slate-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="flex justify-end w-full">
                                <button
                                  className="button"
                                  onClick={sendNotification}
                                >
                                  <span>Schedule Interview</span>
                                </button>
                                </div>
                              </form>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
            </div>
          </div>
      </div>
    </Authenticator>
  );
};
export default CheckResumeOrgAdmin;
