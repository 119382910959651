import React, { useEffect, useState } from 'react';
import { Button, Authenticator } from '@aws-amplify/ui-react';
import axios from 'axios';
import host from '../../global.jsx';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import Loader from '../Loader.jsx';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';

import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";

const CreateMCQSet = () => {
  const [setsData, setSetsData] = useState({});
  const navigate = useNavigate();
  const [selectedSet, setSelectedSet] = useState('');
  const [newSetName, setNewSetName] = useState('');
  const [editIndex, setEditIndex] = useState(null);
  const [editFormData, setEditFormData] = useState({});
  const [isAIMode, setIsAIMode] = useState(false);
  const accessToken = Cookies.get("accessToken");
  const [topics, setTopics] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [questionsPerTopic, setQuestionsPerTopic] = useState({});
  const [isLoadingQuestions, setIsLoadingQuestions] = useState(false);
  const [setName, setSetName] = useState("");
  const [areTopicsLoading, setAreTopicsLoading] = useState(false);
  const [formData, setFormData] = useState({
    question: '',
    option1: '',
    option2: '',
    option3: '',
    option4: '',
    answer: '',
  });
  const [isLoading, setIsLoading] = useState(true);
  const handleBack = () => {
    navigate(-1); // This will take the user back one step in the navigation history
  };

  useEffect(() => {
    const fetchTopics = async () => {
      try {
        setAreTopicsLoading(true);
        const response = await axios.get(`${host}/get_mcq_topics`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        setTopics(response.data);
        setAreTopicsLoading(false);
      } catch (error) {
        if (error.response) {
          const status = error.response.status;
          const detail = error.response.data.detail;
          if (status === 401) {
            await new Promise((resolve) => {
              Cookies.remove("accessToken");
              resolve();
            });
            toast.error(`Unauthorized: ${detail}`);
          } else if (status === 404) {
            toast.warning(`Not Found: ${detail}`);
          } else {
            toast.error(`Error: ${detail}`);
          }
        } else {
          toast.error("An unexpected error occurred. Please try again.");
        }
      }
    };
    fetchTopics();
  }, [accessToken]);

  const handleInputChange = (topic, value) => {
    setQuestionsPerTopic((prev) => ({
      ...prev,
      [topic]: parseInt(value, 10),
    }));
  };

  const handleSetChange = (e) => {
    setSetName(e.target.value);
  };

  const handleSaveSet = async () => {
    try {
      const questionsDict = {};
      questions.forEach((question, index) => {
        questionsDict[index.toString()] = question;
      });

      const payload = {
        data: questionsDict,
      };

      const encodedSetName = encodeURIComponent(setName);

      const res = await axios.post(
        `${host}/create_mcq_set?setName=${encodedSetName}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (res.data.status === "success") {
        toast.success("Data Inserted");
        setSetName("");
        window.location.reload();
      }
    } catch (e) {
      console.error("Error details:", e.response?.data);
      toast.error("Error saving set");
    }
  };

  const handleFetchQuestions = async () => {
    try {
      setIsLoadingQuestions(true);
      const topicCounts = Object.entries(questionsPerTopic)
        .filter(([topic, count]) => count > 0)
        .map(([topic, count]) => ({ topic, count }));

      const response = await axios.post(`${host}/fetchmcq`, topicCounts, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      setQuestions(response.data);
    } catch (error) {
      console.error("Error fetching questions", error);
      toast.error("Error fetching questions");
    } finally {
      setIsLoadingQuestions(false);
    }
  };

  const toggleMode = () => {
    setIsAIMode(!isAIMode);
  };

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${host}/fetch_all_mcqs`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      setSetsData(response.data.sets["0"] || {});
      setIsLoading(false);
    } catch (error) {
      toast.error("Error fetching data");
    }
  };

  useEffect(() => {
    fetchData();      // eslint-disable-next-line
  }, []);

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleEditFormChange = (event) => {
    const { name, value } = event.target;
    setEditFormData({
      ...editFormData,
      [name]: value
    });
  };

  const handleSetQuestion = async (e) => {
    e.preventDefault();
    const { question, option1, option2, option3, option4, answer } = formData;

    if (!question || !option1 || !option2 || !option3 || !option4 || !answer) {
      toast.error("All fields must be filled.");
      return;
    }

    if (new Set([option1, option2, option3, option4]).size !== 4) {
      toast.error("All four options must be different.");
      return;
    }

    if (![option1, option2, option3, option4].includes(answer)) {
      toast.error("Correct answer must match one of the options.");
      return;
    }

    const setName = selectedSet || newSetName;

    if (!setName) {
      toast.error("You must select or create a set.");
      return;
    }

    if (newSetName && Object.keys(setsData).includes(newSetName)) {
      toast.error("A set with this name already exists.");
      return;
    }
    console.log(formData);
    try {
      await axios.post(`${host}/add_mcq_to_set`, formData, {
        params: {
          set_name: setName
        },
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        }
      });
      toast.success("Question Inserted");

      if (newSetName) {
        setSelectedSet(newSetName);
      }

      setFormData({
        question: '',
        option1: '',
        option2: '',
        option3: '',
        option4: '',
        answer: ''
      });
      setNewSetName('');
      fetchData();
    } catch (error) {
      toast.error("Error adding question.");
    }
  };

  const handleEditQuestion = (setName, index) => {
    setEditIndex({ setName, index });
    setEditFormData(setsData[setName][index]);
  };

  const handleSaveQuestion = async (setName, index) => {
    const { question, option1, option2, option3, option4, answer } = editFormData;

    if (!question || !option1 || !option2 || !option3 || !option4 || !answer) {
      toast.error("All fields must be filled.");
      return;
    }

    if (new Set([option1, option2, option3, option4]).size !== 4) {
      toast.error("All four options must be different.");
      return;
    }

    if (![option1, option2, option3, option4].includes(answer)) {
      toast.error("Correct answer must match one of the options.");
      return;
    }
    console.log(editFormData);
    try {
      await axios.put(`${host}/editmcq`, editFormData, {
        params: {
          set_name: setName,
          index: index
        },
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        },
      });
      toast.success("Question Updated Successfully!");
      setEditIndex(null);
      fetchData();
    } catch (error) {
      toast.error("Error updating question.");
    }
  };

  const handleDeleteQuestion = async (setName, index) => {
    try {
      await axios.delete(`${host}/deletemcq`, {
        params: {
          set_name: setName,
          index: index,
        },
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
      });
      toast.success("Question Deleted Successfully!");
      fetchData();
    } catch (error) {
      toast.error("Error deleting question.");
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Authenticator>
        <div className="p-8">
          <div className="table-container glass-effect">
            <div className="flex justify-between px-8 py-2">
              <div className="flex items-center space-x-4">
                <button
                  onClick={handleBack}
                  className="flex items-center space-x-2 text-white hover:text-gray-300 transition-colors"
                >
                  <ArrowLeft size={24} />
                  <span>Back</span>
                </button>
                <h1 className="text-4xl font-bold text-white">
                  <span>MCQ</span>
                </h1>
              </div>
              <div
                className="relative w-[300px] h-12 rounded-full cursor-pointer transition-all duration-500 bg-gradient-to-l from-[#FB54AE] to-[#2F64FF]"
                onClick={toggleMode}
              >

                <div
                  className={`absolute top-1 h-10 w-[148px] bg-black rounded-full shadow-md 
          transition-transform duration-500 ${isAIMode ? "translate-x-[146px]" : "translate-x-1"}`}
                />

                <div
                  className={`absolute inset-y-0 left-0 w-[147px] flex items-center justify-center text-sm font-semibold
          ${isAIMode ? "text-white" : "text-blue-600"}`}
                >
                  Generate with AI
                </div>

                <div
                  className={`absolute inset-y-0 right-0 w-[147px] flex items-center justify-center text-sm font-semibold
          ${isAIMode ? "text-pink-400" : "text-white"}`}
                >
                  Create Your Set
                </div>
              </div>
            </div>
            {isAIMode ? (
              <div className="flex flex-col md:flex-row justify-center gap-8 p-10 ">
                {/* Create Your Own Set */}
                <div className="flex flex-col w-full lg:w-[30%] h-[calc(100vh-120px)] overflow-y-auto">
                  <h1 className="text-lg font-semibold text-gray-800 mb-7 text-left text-white">Create Your Own Set</h1>
                  <form class="space-y-4">
                    <div class="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
                      <label className="text-white w-full sm:w-1/3 mt-1" htmlFor="setSelect">
                        Select a Set
                      </label>
                      <select
                        id="setSelect"
                        className="glass-effect text-gray-400 text-md border border-gray-300 rounded-full px-3 py-2 w-full sm:w-2/3"
                        value={selectedSet}
                        onChange={(e) => setSelectedSet(e.target.value)}
                      >
                        <option style={{ background: "rgba(34, 52, 97, 0.35)", color: "black" }} value="">
                          Select Set Name
                        </option>
                        {Object.keys(setsData).map((setName) => (
                          <option
                            key={setName}
                            value={setName}
                            style={{ background: "rgba(34, 52, 97, 0.35)", color: "black" }}
                          >
                            SET {setName}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div class="flex flex-col sm:flex-row items-center space-y-4 sm:space-y-0 sm:space-x-4">
                      <label class="text-white w-full sm:w-1/3 whitespace:nowrap" for="newSetName">
                        Create New
                      </label>
                      <input
                        id="newSetName"
                        name="newSetName"
                        class="glass-effect text-white text-md border border-gray-300 rounded-full px-3 py-2 w-full sm:w-2/3 backdrop-blur-[10px] bg-white/30"
                        placeholder="Enter New Set Name"
                        value={newSetName}
                        onChange={(e) => setNewSetName(e.target.value)}
                        disabled={!!selectedSet}
                      />
                    </div>

                    <input
                      name="question"
                      placeholder="Enter Question"
                      class="glass-effect text-white text-md border border-gray-300 rounded-full w-full px-4 py-2 backdrop-blur-[10px] bg-white/30"
                      value={formData.question}
                      onChange={handleFormChange}
                    />

                    <div class="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
                      <input
                        name="option1"
                        placeholder="Enter Option 1"
                        class="glass-effect text-white text-md border border-gray-300 rounded-full w-full px-4 py-2 backdrop-blur-[10px] bg-white/30"
                        value={formData.option1}
                        onChange={handleFormChange}
                      />
                      <input
                        name="option2"
                        placeholder="Enter Option 2"
                        class="glass-effect text-white text-md border border-gray-300 rounded-full w-full px-4 py-2 backdrop-blur-[10px] bg-white/30"
                        value={formData.option2}
                        onChange={handleFormChange}
                      />
                    </div>

                    <div class="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
                      <input
                        name="option3"
                        placeholder="Enter Option 3"
                        class="glass-effect text-white text-md border border-gray-300 rounded-full w-full px-4 py-2 backdrop-blur-[10px] bg-white/30"
                        value={formData.option3}
                        onChange={handleFormChange}
                      />
                      <input
                        name="option4"
                        placeholder="Enter Option 4"
                        class="glass-effect text-white text-md border border-gray-300 rounded-full w-full px-4 py-2 backdrop-blur-[10px] bg-white/30"
                        value={formData.option4}
                        onChange={handleFormChange}
                      />
                    </div>

                    <input
                      name="answer"
                      placeholder="Enter Answer"
                      class="glass-effect text-white text-md border border-gray-300 rounded-full w-full px-4 py-2 backdrop-blur-[10px] bg-white/30"
                      value={formData.answer}
                      onChange={handleFormChange}
                    />

                    <div class="flex justify-center">
                      <button
                        class="button mx-auto mt-2.5 bg-white/10 backdrop-blur-sm rounded px-5 py-2.5 text-black shadow-lg font-normal text-base w-auto max-w-[300px] box-border"
                        onClick={handleSetQuestion}
                      >
                        <span>Add Question</span>
                      </button>
                    </div>
                  </form>
                </div>

                <div className="flex flex-col w-full lg:w-[70%] border border-gray-300 rounded-2xl p-4 h-[calc(100vh-120px)]">
                  <h3 className="text-md font-semibold text-gray-300 mb-2 text-left">Questions</h3>
                  <div className="overflow-y-auto flex-grow">
                    {selectedSet ? (
                      <div class="displayQuestions">
                        {setsData[selectedSet] && (
                          <div class="questionsContainer">

                            {Object.entries(setsData[selectedSet]).map(([key, question], index) => (
                              <div
                                key={key}
                                class="displayedQuestions border-b border-gray-300 py-4"
                              >
                                {editIndex &&
                                  editIndex.setName === selectedSet &&
                                  editIndex.index === Number(key) ? (
                                  <>
                                    <div className="flex items-center mb-4">
                                      <label className="font-medium mr-4 text-gray-300">
                                        {editIndex.index + 1}.
                                      </label>
                                      <input
                                        name="question"
                                        placeholder="Enter Question"
                                        className="glass-effect text-gray-300 border border-gray-300 rounded w-full px-4 py-2 font-medium"
                                        value={editFormData.question}
                                        onChange={handleEditFormChange}
                                      />
                                    </div>

                                    <div className="flex items-center mb-4">
                                      <label className="font-medium text-gray-300 mr-4">a</label>
                                      <input
                                        name="option1"
                                        placeholder="Enter Option A"
                                        className="glass-effect text-gray-300 border border-gray-300 rounded w-full px-4 py-2"
                                        value={editFormData.option1}
                                        onChange={handleEditFormChange}
                                      />
                                    </div>

                                    <div className="flex items-center mb-4">
                                      <label className="font-medium text-gray-300 mr-4">b</label>
                                      <input
                                        name="option2"
                                        placeholder="Enter Option B"
                                        className="glass-effect text-gray-300 border border-gray-300 rounded w-full px-4 py-2"
                                        value={editFormData.option2}
                                        onChange={handleEditFormChange}
                                      />
                                    </div>

                                    <div className="flex items-center mb-4">
                                      <label className="font-medium text-gray-300 mr-4">c</label>
                                      <input
                                        name="option3"
                                        placeholder="Enter Option C"
                                        className="glass-effect text-gray-300 border border-gray-300 rounded w-full px-4 py-2"
                                        value={editFormData.option3}
                                        onChange={handleEditFormChange}
                                      />
                                    </div>

                                    <div className="flex items-center mb-4">
                                      <label className="font-medium text-gray-300 mr-4">d</label>
                                      <input
                                        name="option4"
                                        placeholder="Enter Option D"
                                        className="glass-effect text-gray-300 border border-gray-300 rounded w-full px-4 py-2"
                                        value={editFormData.option4}
                                        onChange={handleEditFormChange}
                                      />
                                    </div>

                                    <div className="flex items-center mb-4">
                                      <label className="font-medium text-gray-300 mr-4">Answer</label>
                                      <input
                                        name="answer"
                                        placeholder="Enter Answer"
                                        className="glass-effect text-gray-300 border border-gray-300 rounded w-full px-4 py-2"
                                        value={editFormData.answer}
                                        onChange={handleEditFormChange}
                                      />
                                    </div>
                                    <div class="flex space-x-4 mt-2">
                                      <button
                                        type="button"
                                        onClick={() =>
                                          handleSaveQuestion(selectedSet, Number(key))
                                        }
                                        className="mt-4 border border-green-600 text-white py-1 px-4 rounded hover:bg-green-500"
                                      >
                                        Save
                                      </button>
                                      <button
                                        type="button"
                                        onClick={() => setEditIndex(null)}
                                        className="mt-4 border border-blue-600 text-white py-1 px-4 rounded hover:bg-blue-500"
                                      >
                                        Cancel
                                      </button>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <p class="displayedQuestion text-gray-700 font-medium">
                                      <div className="text-base font-medium text-gray-300 text-left mb-2"
                                        style={{
                                          fontFamily: "Poppins",
                                          whiteSpace: "pre-wrap",
                                          wordWrap: "break-word",
                                          wordBreak: "break-word",
                                        }}
                                      >
                                        {Number(index) + 1}.{" "}
                                        {setsData[selectedSet][key].question}
                                      </div>
                                    </p>
                                    <ul class="list-none text-gray-300 text-base">
                                      <li>a. {setsData[selectedSet][key].option1}</li>
                                      <li>b. {setsData[selectedSet][key].option2}</li>
                                      <li>c. {setsData[selectedSet][key].option3}</li>
                                      <li>d. {setsData[selectedSet][key].option4}</li>
                                    </ul>
                                    <p class="text-gray-300 mt-2">
                                      Correct Answer:{" "}
                                      <span class="font-medium">
                                        {setsData[selectedSet][key].answer}
                                      </span>
                                    </p>
                                    <div class="flex space-x-4 mt-2">
                                      <button
                                        type="button"
                                        class="border border-yellow-300 editBtn py-1 px-4 rounded text-gray-300 mb-2 hover:bg-yellow-600"
                                        onClick={() =>
                                          handleEditQuestion(selectedSet, Number(key))
                                        }
                                      >
                                        Edit
                                      </button>
                                      <button
                                        type="button"
                                        class="border border-red-300 deleteBtn py-1 px-4 rounded text-gray-300 mb-2 hover:bg-red-500"
                                        onClick={() =>
                                          handleDeleteQuestion(selectedSet, Number(key))
                                        }
                                      >
                                        Delete
                                      </button>
                                    </div>
                                  </>
                                )}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    ) : (
                      <div>
                        {/* <img
                  className="mx-auto w-1/2 h-auto"
                  src="/fetch-question.jpg"
                  alt="Question"
                /> */}
                        <p className="mt-10 text-center text-xs text-gray-400">
                          Feels empty! Select or Create a Set for questions to appear.
                        </p>
                      </div>

                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div>
                {areTopicsLoading ? (
                  <Loader />
                ) : (
                  <div className="flex flex-col md:flex-row justify-center gap-8 p-10">
                    {/* Create MCQ's with AI */}
                    <div className="flex flex-col w-full lg:w-[30%] h-[calc(100vh-120px)]">
                      <h1 className="text-lg font-semibold text-white mb-7 text-left">
                        Create MCQ's with AI
                      </h1>
                      <div className="flex flex-col gap-5 m-2 overflow-y-auto">
                        {topics.map((topic) => (
                          <div
                            key={topic}
                            className="flex items-center justify-between space-y-2"
                          >
                            <div className="text-md text-white">{topic}</div>
                            <input
                              type="number"
                              min="0"
                              max="10"
                              onChange={(e) => handleInputChange(topic, e.target.value)}
                              className="text-white glass-effect border border-1 rounded w-20 appearance-none"
                              aria-label={`Number of Questions for ${topic}`}
                            />
                          </div>
                        ))}
                      </div>
                      <button
                        class="button mx-auto mt-2.5 bg-white/10 backdrop-blur-sm rounded px-5 py-2.5 text-black shadow-lg font-normal text-base w-auto max-w-[300px] box-border"
                        onClick={handleFetchQuestions}
                      >
                        <span>Fetch Questions</span>
                      </button>
                      <div className="mt-6 text-center">
                        {questions.length > 0 && (
                          <div className="saveAsSet mb-4 flex items-center justify-center space-x-2">
                            <label className="mr-2 whitespace-nowrap text-md text-white">
                              Save As Set:
                            </label>
                            <input
                              placeholder="Set Name"
                              value={setName}
                              onChange={handleSetChange}
                              className="text-white glass-effect border border-gray-300 rounded p-2 w-40 text-md"
                            />
                            <button
                              onClick={handleSaveSet}
                              className="button text-md py-2 px-4 rounded hover:font-semibold"
                            >
                              <span>Save</span>
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                    {/* Question Box */}
                    {isLoadingQuestions ? (
                      <div className="flex flex-col w-full lg:w-[70%] border border-gray-300 rounded-2xl p-4 h-[calc(100vh-120px)]">
                        <h3 className="text-md font-semibold text-gray-300 mb-2 text-left">
                          Questions
                        </h3>
                        <div className="animate-pulse overflow-y-auto flex-grow">
                          {[1, 2, 3].map((i) => (
                            <div key={i} className="mb-8 mt-4">
                              <div className="h-6 bg-gray-500 rounded w-3/4 mb-4"></div>
                              {[1, 2, 3, 4].map((j) => (
                                <div
                                  key={j}
                                  className="h-4 bg-gray-500 rounded w-1/2 mb-2 ml-4"
                                ></div>
                              ))}
                              <div className="border-b border-gray-500 mt-4"></div>
                            </div>
                          ))}
                        </div>
                      </div>
                    ) : questions.length > 0 ? (
                      <div className="flex flex-col w-full lg:w-[70%] border border-gray-300 rounded-2xl p-4 h-[calc(100vh-120px)]">
                        <h3 className="text-md font-semibold text-gray-300 mb-2 text-left">
                          Questions
                        </h3>
                        <div className="overflow-y-auto flex-grow">
                          {questions.map((question, index) => (
                            <div key={index} className="question rounded">
                              <h3 className="text-base font-medium text-gray-300 text-left mt-4 mb-2">
                                {Number(index) + 1}.{" "}
                                {question.question}
                              </h3>
                              <ul className="list-none text-gray-300 text-base">
                                <li>a. {question.option1}</li>
                                <li>b. {question.option2}</li>
                                <li>c. {question.option3}</li>
                                <li>d. {question.option4}</li>
                              </ul>
                              <div className="border-b border-gray-300 mt-4"></div>
                            </div>
                          ))}
                        </div>
                      </div>
                    ) : (
                      <div className="flex flex-col w-full lg:w-[70%] border border-gray-300 rounded-2xl p-4 h-[calc(100vh-120px)]">
                        <h3 className="text-md font-semibold text-gray-300 mb-2 text-left">
                          Questions
                        </h3>
                        {/* <img
                  className="mx-auto w-1/2 h-auto"
                  src="/fetch-question.jpg"
                  alt="Question"
                /> */}
                        <p className="mt-10 text-center text-xs text-gray-400">
                          Feels empty! Select or Create a Set for questions to appear.
                        </p>
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}

          </div>
        </div>
      </Authenticator>
    </>
  );
};

export default CreateMCQSet;
