import React, { useEffect, useRef, useState } from "react";
import { Authenticator } from "@aws-amplify/ui-react";
import host from "../../global";
import { getCurrentUser } from "aws-amplify/auth";
import PersonAddAltRoundedIcon from "@mui/icons-material/PersonAddAltRounded";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from "@mui/material";
import { Camera } from 'lucide-react';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from "../Loader";
const profile_photos_host = process.env.REACT_APP_PROFILE_PHOTOS_HOST;
const EditProfile = () => {
  const fileInputRef = useRef(null);
  const [shouldSubmit, setShouldSubmit] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [profileData, setProfileData] = useState({
    name: "",
    email: "",
    contact: "",
    profileImage: "",
    profileImageFile: null,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const accessToken = Cookies.get('accessToken');
  useEffect(() => {
    if (shouldSubmit) {
      handleSubmit();
      setShouldSubmit(false);
    }
  }, [shouldSubmit, accessToken]);
  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        setIsLoading(true);
        const response = await fetch(`${host}/gethrdetails`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        const data = await response.json();
        const { userId } = await getCurrentUser();
        setProfileData({
          name: data.name,
          email: data.email,
          contact: data.contact,
          profileImage: data.profile ? `${profile_photos_host}/HRProfiles/${userId}_${data.profile}` : "",
        });
        setIsLoading(false);
      } catch (error) {
        if (error.response) {
          const status = error.response.status;
          const detail = error.response.data.detail;
          if (status === 401) {
            await new Promise((resolve) => {
              Cookies.remove('accessToken');
              resolve();
            });
            toast.error(`Unauthorized: ${detail}`);
          } else if (status === 404) {
            toast.warning(`Not Found: ${detail}`);
          } else {
            toast.error(`Error: ${detail}`);
          }
        } else {
          toast.error('An unexpected error occurred. Please try again.');
        }
      }
    };
    fetchProfileData();
  }, [accessToken]);
  const handleChange = (e) => {
    const { name, files } = e.target;

    if (name === "profileImage" && files.length > 0) {
      const file = files[0];

      const validImageTypes = ["image/jpeg", "image/jpg", "image/png"];
      if (!validImageTypes.includes(file.type)) {
        setMessage("Invalid file type. Please upload a PNG, JPG, or JPEG image.");
        return;
      }
      const maxSize = 1 * 1024 * 1024;
      if (file.size > maxSize) {
        setMessage("File size exceeds 1MB. Please upload a smaller image.");
        return;
      }
      const imageUrl = URL.createObjectURL(file);
      console.log(imageUrl)
      setProfileData((prevData) => ({
        ...prevData,
        profileImage: imageUrl,
        profileImageFile: file,
      }));
      setIsLoading(true);
      setShouldSubmit(true);
    } else {
      setProfileData((prevData) => ({
        ...prevData,
        [name]: e.target.value,
      }));
    }
  };
  const handleDeleteImage = () => {
    setProfileData((prevData) => ({
      ...prevData,
      profileImage: "",
      profileImageFile: null,
      deleteProfileImage: true,
    }));
    setIsLoading(true);
    setShouldSubmit(true);
  };
  const handleSubmit = async (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsSubmitting(true);
    const formData = new FormData();
    formData.append("hrName", profileData.name);
    formData.append("hremail", profileData.email);
    formData.append("contactNumber", profileData.contact);
    if (profileData.profileImageFile) {
      formData.append("profile", profileData.profileImageFile);
    }
    if (profileData.deleteProfileImage) {
      formData.append("deleteProfileImage", "true");
    }
    console.log(profileData);
    console.log(formData);
    try {
      const response = await fetch(`${host}/hr/updateprofile`, {
        method: "PUT",
        body: formData,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (response.ok) {
        toast.success("Profile updated successfully!");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        const errorData = await response.json();
        console.error("Error details:", errorData);
        toast.error(
          "Failed to update profile. " +
          errorData.detail.map((err) => err.msg).join(", ")
        );
      }
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        const detail = error.response.data.detail;
        if (status === 401) {
          await new Promise((resolve) => {
            Cookies.remove('accessToken');
            resolve();
          });
          toast.error(`Unauthorized: ${detail}`);
        } else if (status === 404) {
          toast.warning(`Not Found: ${detail}`);
        } else {
          toast.error(`Error: ${detail}`);
        }
      } else {
        toast.error('An unexpected error occurred. Please try again.');
      }
    }
    setIsSubmitting(false);
  };
  const handleEditProfile = () => {
    fileInputRef.current.click();
  };
  const handleImageClick = () => {
    setIsModalOpen(true);
  };
  if (isLoading) {
    return (
      <Loader />
    )
  }
  return (
    <Authenticator>
<div className="h-screen overflow-auto flex justify-center items-center">
  {isLoading ? (
    <Loader />
  ) : (
    <div className="w-full max-w-md py-2 px-4">
      <h2 className="text-center text-2xl font-semibold text-white mb-6">
        Edit Profile
      </h2>
      <form onSubmit={handleSubmit}>
        <div className="flex justify-center items-center mb-6 relative">
          {profileData.profileImage ? (
            <div className="relative">
              <img
                src={profileData.profileImage}
                alt="Profile"
                className="w-24 h-24 rounded-full object-cover border-2 border-gray-300 cursor-pointer"
                onClick={handleImageClick}
              />
              <Camera
                className="absolute bottom-0 right-0 text-white bg-black bg-opacity-50 rounded-full p-2 cursor-pointer w-8 h-8"
                onClick={handleImageClick}
              />
            </div>
          ) : (
            <div
              onClick={handleImageClick}
              className="flex justify-center items-center bg-black rounded-full w-24 h-24 cursor-pointer relative"
            >
            <img
              className="shadow-lg p-4"
              src="/user.png"
            />
              <Camera
                className="absolute bottom-0 right-0 text-white bg-black bg-opacity-50 rounded-full p-2 cursor-pointer w-8 h-8"
                onClick={handleImageClick}
              />
            </div>
          )}
          <input
            type="file"
            name="profileImage"
            accept="image/*"
            ref={fileInputRef}
            className="hidden"
            onChange={handleChange}
          />
        </div>

        {/* Modal */}
        <Dialog
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          PaperProps={{
            sx: {
              width: "37%",
              maxWidth: "350px",
              height: "60%",
              borderRadius: "20px",
              padding: "30px",
            },
          }}
        >
          <DialogContent className="flex flex-col justify-center h-full overflow-hidden">
            {profileData.profileImage ? (
              <img
                src={profileData.profileImage}
                alt="Profile"
                className="w-32 h-32 rounded-full object-cover border-2 border-gray-300 mb-2 self-center"
              />
            ) : (
              <img
              className="w-32 h-32 self-center bg-black/30 rounded-full p-2 border-1 border-white"
              src="/user.png"
            />
            )}
            <div className="text-center font-bold text-lg text-gray-800">
              {profileData.name}
            </div>
            <div className="text-center text-sm text-gray-500">
              {profileData.department}
            </div>
          </DialogContent>
          <DialogActions>
            <div className="w-full flex justify-evenly -mt-8">
            <IconButton
              onClick={handleEditProfile}
              className="flex flex-col items-center w-16 h-14 rounded-lg border border-gray-400"
            >
              <EditIcon className="text-gray-800 mb-1" />
              <span className="text-xs text-gray-800">
                Edit
              </span>
            </IconButton>
            <IconButton
              onClick={handleDeleteImage}
              className="flex flex-col items-center w-16 h-14 rounded-lg border border-gray-400"
            >
              <DeleteIcon className="text-gray-800 mb-1" />
              <span className="text-xs text-gray-800">
                Remove
              </span>
            </IconButton>
            </div>
          </DialogActions>
        </Dialog>

        {/* Form Fields */}
        <div className="mb-4">
          <label className="block font-light text-white/50 ml-2 mb-1">
            Name
          </label>
          <input
            type="text"
            name="name"
            value={profileData.name}
            onChange={handleChange}
            required
            className="w-full p-2 border border-gray-300 rounded-full px-4 focus:ring focus:ring-blue-300"
          />
        </div>
        <div className="mb-4">
          <label className="block mb-1 font-light text-white/50 ml-2">
            Email
          </label>
          <input
            type="email"
            name="email"
            value={profileData.email}
            onChange={handleChange}
            required
            className="w-full p-2 border border-gray-300 rounded-full px-4  focus:ring focus:ring-blue-300"
          />
        </div>
        <div className="mb-4">
          <label className="block font-light text-white/50 mb-1 ml-2">
            Contact
          </label>
          <input
            type="text"
            name="contact"
            value={profileData.contact}
            onChange={handleChange}
            required
            className="w-full p-2 border border-gray-300 rounded-full px-4 focus:ring focus:ring-blue-300"
          />
        </div>

        {/* Submit Button */}
        <div className="flex justify-center py-2">
        <button
          type="submit"
          disabled={isSubmitting}
          className={`button`}
        >
          <span> {isSubmitting ? "Updating..." : "Update Profile"}</span>
        </button>
        </div>
      </form>
      {message && <p className="text-center text-green-500 mt-3">{message}</p>}
    </div>
  )}
</div>

    </Authenticator>
  );
};
export default EditProfile;
