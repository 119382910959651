import React, { useState, useEffect, useRef } from "react";
import { Button, TextField, IconButton } from "@mui/material";
import { CircleUserRound } from "lucide-react";
import {Bell} from "lucide-react"
import { useNavigate,Link } from "react-router-dom";
import "../../css/HRPortal.css";
import axios from "axios";
import host from "../../global";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { signOut } from "@aws-amplify/auth";
import { getCurrentUser } from "aws-amplify/auth";
import { LogOut, UserPen } from "lucide-react";
import { useLocation } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [notification, setNotification] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [noResults, setNoResults] = useState(false);
  const accessToken = Cookies.get("accessToken");
  const [userData, setUserData] = useState(null);
  const [userId, setUserId] = useState(null);
  const availablePages = [
    { name: "Home", route: `/hr/dashboard` },
    { name: "Candidate Reports", route: `/hr/candidatereports` },
    { name: "Job Vacancies", route: `/hr/jobvacancies` },
    { name: "Profile", route: `/hr/hreditprofile` },
    { name: "Single Resume Parser", route: `/hr/checkresume` },
    { name: "Bulk Resume Parser", route: `/hr/BulkResume` },
  ];
  const fetchProfileData = async () => {
    try {
      const { userId } = await getCurrentUser();
      setUserId(userId);
      const response = await axios.get(`${host}/gethrdetails`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      setUserData(response.data);
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        const detail = error.response.data.detail;
        if (status === 401) {
          await new Promise((resolve) => {
            Cookies.remove("accessToken");
            resolve();
          });
          toast.error(`Unauthorized: ${detail}`);
        } else if (status === 404) {
          toast.warning(`Not Found: ${detail}`);
        } else {
          toast.error(`Error: ${detail}`);
        }
      } else {
        toast.error("An unexpected error occurred. Please try again.");
      }
    }
  };
  useEffect(() => {
    fetchProfileData();
  }, [accessToken]);
  const handleSignOut = async () => {
    try {
      await signOut();
      navigate("/");
    } catch (error) {
      console.error("Error signing out", error);
      toast.error("Error signing out");
    }
  };
  const profileImageUrl = userData?.profile
    ? `${process.env.REACT_APP_PROFILE_PHOTOS_HOST}/HRProfiles/${userId}_${userData.profile}`
    : "";

  const handleSearchInputChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    if (query) {
      const filteredSuggestions = availablePages.filter((page) =>
        page.name.toLowerCase().includes(query.toLowerCase())
      );
      setSuggestions(filteredSuggestions);
      setNoResults(filteredSuggestions.length === 0);
    } else {
      setSuggestions([]);
      setNoResults(false);
    }
  };
  const handleSuggestionClick = (route) => {
    navigate(route);
    setSearchQuery("");
    setSuggestions([]);
  };

  const handlenotificationClick = (upload_id) => {
    navigate(`/hr/BulkResume/Bulkresumemodel/${upload_id}`);
  };
  useEffect(() => {
    const fetchBulkResumeUploadResult = async () => {
      try {
        const response = await axios.get(`${host}/bulkuploadresult`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        setNotification(response.data.results);
      } catch (error) {
        if (error.response) {
          const status = error.response.status;
          const detail = error.response.data.detail;
          if (status === 401) {
            await new Promise((resolve) => {
              Cookies.remove("accessToken");
              resolve();
            });
            toast.error(`Unauthorized: ${detail}`);
          } else if (status === 404) {
            toast.warning(`Not Found: ${detail}`);
          } else {
            toast.error(`Error: ${detail}`);
          }
        } else {
          toast.error("An unexpected error occurred. Please try again.");
        }
      }
    };
    fetchBulkResumeUploadResult();
  }, [accessToken]);
  return (
    <>
      <header className="main-header flex justify-between items-center py-3 px-8 flex-wrap box-border">
        <nav>
        <Link to="/hr/dashboard">
        <img src="../Homepagelogo.png" alt="Logo" className="mr-2" style={{height:"34px",width:"24px"}}/>
        </Link>
        <button 
  onClick={() => navigate(`/hr/dashboard`)} 
  className={`${
    location.pathname.includes('dashboard') 
      ? 'text-transparent bg-gradient-to-r from-pink-500 to-blue-500 bg-clip-text relative'
      : 'bg-white bg-clip-text text-transparent'
  }`} >
  Home
  {location.pathname.includes('dashboard') && (
    <span className="absolute bottom-0 left-1/2 transform -translate-x-1/2 w-3/5 h-0.5 bg-gradient-to-r from-pink-500 to-blue-500"></span>
  )}
</button>
        <button 
  onClick={() => navigate(`/hr/candidatereports`)} 
  className={`${
    location.pathname.includes('candidatereports') 
      ? 'text-transparent bg-gradient-to-r from-pink-500 to-blue-500 bg-clip-text relative'
      : 'bg-white bg-clip-text text-transparent'
  }`} >
  Candidate Reports
  {location.pathname.includes('candidatereports') && (
    <span className="absolute bottom-0 left-1/2 transform -translate-x-1/2 w-4/5 h-0.5 bg-gradient-to-r from-pink-500 to-blue-500"></span>
  )}
</button>
          <button 
  onClick={() => navigate(`/hr/jobvacancies`)} 
  className={`${
    location.pathname.includes('jobvacancies') 
      ? 'text-transparent bg-gradient-to-r from-pink-500 to-blue-500 bg-clip-text relative'
      : 'bg-white bg-clip-text text-transparent'
  }`} >
  Job Vacancies
  {location.pathname.includes('jobvacancies') && (
    <span className="absolute bottom-0 left-1/2 transform -translate-x-1/2 w-4/5 h-0.5 bg-gradient-to-r from-pink-500 to-blue-500"></span>
  )}
</button>
        </nav>
        <div className="main-header-actions flex items-center gap-4">
          {/* <TextField
            type="search"
            placeholder="Search..."
            variant="outlined"
            size="small"
            className="search-input"
            value={searchQuery}
            onChange={handleSearchInputChange}
          />
          {suggestions.length > 0 && (
            <div className="suggestions-dropdown">
              <ul>
                {suggestions.map((suggestion, index) => (
                  <li
                    key={index}
                    onClick={() => handleSuggestionClick(suggestion.route)}
                  >
                    {suggestion.name}
                  </li>
                ))}
              </ul>
            </div>
          )} */}

          {noResults && <p>No results found</p>}
          <div className="relative group">
            <IconButton>
              <Bell size={28} color="white"/>
              
            </IconButton>
            {notification.length > 0 && (
                <span className="absolute top-1 right-1 flex items-center justify-center 
                                w-5 h-5 bg-red-600 text-white text-xs font-bold 
                                rounded-full border-2 border-gray-900">
                  {notification.length}
                </span>
              )}
            <div className="hidden group-hover:block absolute right-0 top-15 rounded-xl overflow-hidden z-[100]">
              <div className="bg-gradient-to-br from-[rgba(34,52,97,0.25)] to-[rgba(11,10,31,0.05)] backdrop-blur-[50px] rounded-xl fixed right-4 top-15 z-[100]" style={{border:"solid 0.2px purple"}}>
                {notification.length > 0 ? (
                  <div className="max-h-64 overflow-y-auto w-80">
                    <ul className="divide-y divide-gray-300">
                      {notification.map(({ upload_id, job_role }, index) => (
                        <li
                          key={index}
                          onClick={() => handlenotificationClick(upload_id)}
                          className="cursor-pointer p-4"
                        >
                          <div className="flex items-center space-x-3">
                            <div className="text-green-500">
                              <CheckCircleOutlineRoundedIcon size={24} className="flex-shrink-0" />
                            </div>
                            <div className="flex-grow">
                              <p className="text-sm text-white/50 hover:text-white">
                                All resumes for the {job_role} position have
                                been successfully parsed.
                              </p>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : (
                  <div className="w-64 p-4 text-center text-gray-500">
                    No New Notifications.
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="group relative ml-2">
          <img
            className="w-[30x] h-[30px] text-white rounded-full border-1 border-white"
            src="/user.png"
          />
            <div className="hidden group-hover:flex group-hover:items-start absolute top-full right-0 z-50 p-1 flex-col">
              <div className="w-[250px] rounded-2xl shadow-2xl overflow-hidden" style={{border:"solid 0.2px purple"}}>
                <div className="relative">
                  {userData ? (
                    <div className="bg-gradient-to-br from-[rgba(34,52,97,0.25)] to-[rgba(11,10,31,0.05)] backdrop-blur-[50px]">
                    <div className="relative z-10 flex flex-col sm:flex-col items-center p-2">
                      {profileImageUrl ? (
                        <img
                          className="w-[110px] h-[110px] rounded-full border-4 border-white shadow-lg object-cover"
                          src={profileImageUrl}
                          alt={userData?.name ? userData.name[0].toUpperCase() : "Profile Image"}
                        />
                      ) : (
                        <img
                        className="w-[90px] h-[90px] rounded-full border-1 border-white shadow-lg object-cover p-4 mb-2"
                        src="/user.png"
                      />
                      )}
                      <div className="flex flex-col gap-2 justify-center items-center">
                        <h2 className="text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-pink-500 to-blue-500">
                          {userData?.name || "No Name Available"}
                        </h2>
                        <p className="text-medium text-white/90 font-medium -mt-2">
                        {userData?.department || "No Department"}
                        </p>
                        <div className="flex justify-center sm:justify-start gap-6 p-2 mb-2">
                          <UserPen
                            className="text-blue-600 bg-blue-100 rounded-full p-2 w-10 h-10 hover:bg-blue-200 hover:text-blue-700 transition-all duration-300 cursor-pointer"
                            onClick={() => navigate(`/hr/hreditprofile`)}
                          />
                          <LogOut
                            className="text-red-600 bg-red-100 rounded-full p-2 w-10 h-10 hover:bg-red-200 hover:text-red-700 transition-all duration-300 cursor-pointer"
                            onClick={handleSignOut}
                          />
                        </div>
                      </div>
                    </div>
                    </div>
                  ) : (
                    <div class="relative flex w-64 animate-pulse gap-2 p-4">
                      <div class="h-12 w-12 rounded-full bg-slate-400"></div>
                      <div class="flex-1">
                        <div class="mb-1 h-5 w-3/5 rounded-lg bg-slate-400 text-lg"></div>
                        <div class="h-5 w-[90%] rounded-lg bg-slate-400 text-sm"></div>
                      </div>
                      <div class="absolute bottom-5 right-0 h-4 w-4 rounded-full bg-slate-400"></div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};
export default Header;
