import React, { useState, useEffect } from 'react';
import { TextField, Pagination } from '@mui/material';
import axios from 'axios';
import host from '../../global';
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../Loader';
const SegmentedProgressBar = ({ processComplete, inProgress, testPending, total }) => {
  const getSegmentStyle = (value, color) => ({
    width: `${total > 0 ? (value / total) * 100 : 0}%`,
    backgroundColor: color,
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '5px',
    margin: '1px',
  });
  const totalCount = processComplete + inProgress + testPending;
  return (
    <div style={{ display: 'flex', height: '35px', borderRadius: '4px', overflow: 'hidden', width: '100%' }}>
      {processComplete > 0 && (
        <div style={getSegmentStyle(processComplete, '#069855')}>
          <span style={{ color: '#fff' }}>{processComplete}</span>
        </div>
      )}
      {inProgress > 0 && (
        <div style={getSegmentStyle(inProgress, 'rgba(243, 200, 102, 1)')}>
          <span style={{ color: '#000' }}>{inProgress}</span>
        </div>
      )}
      {testPending > 0 && (
        <div style={getSegmentStyle(testPending, '#D9D9D9')}>
          <span style={{ color: '#000' }}>{testPending}</span>
        </div>
      )}
      {totalCount === 0 && <div style={{ width: '100%', backgroundColor: '#E6E8F0' }} />}
    </div>
  );
};
export default function JobVacanciesTable() {
  const [jobPositions, setJobPositions] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredJobPositions, setFilteredJobPositions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const accessToken = Cookies.get('accessToken');
  useEffect(() => {
    const fetchJobPositions = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(`${host}/getjobpositionsdata`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          });
        setJobPositions(response.data);
        setFilteredJobPositions(response.data);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        if (error.response) {
          const status = error.response.status;
          const detail = error.response.data.detail;
          if (status === 401) {
            await new Promise((resolve) => {
              Cookies.remove('accessToken');
              resolve();
            });
            toast.error(`Unauthorized: ${detail}`);
          } else if (status === 404) {
            toast.warning(`Not Found: ${detail}`);
          } else {
            toast.error(`Error: ${detail}`);
          }
        } else {
          toast.error('An unexpected error occurred. Please try again.');
        }
      }
    };
    fetchJobPositions();
  }, [accessToken]);
  useEffect(() => {
    const filtered = jobPositions
      .filter((job) =>
        job.job_role.toLowerCase().includes(searchQuery.toLowerCase())
      )
      .sort((a, b) => new Date(b.posting_date) - new Date(a.posting_date));
    setFilteredJobPositions(filtered);
  }, [searchQuery, jobPositions]);
  if (isLoading) {
    return (
      <Loader />
    )
  }
  return (
    <div className="flex justify-center items-center w-full">
    <div className="job-vacancies flex justify-center w-[95%] p-8">
      <div className="table-container glass-effect">
        <div className="table-header" >
          <h1 className='text-white'>Job Vacancies</h1>
          <div className="px-16 search-option" style={{ display: 'flex', alignItems: 'center' }}>
            <div className="search-input" style={{ marginRight: '20px', marginBottom: '10px' }}>
              <TextField
                type="search"
                placeholder="Search by Job Role"
                variant="outlined"
                size="small"
                className="search-input"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                style={{
                  marginLeft: "10px",
                  minWidth: 250,
                  maxWidth: 300,
                }}
                InputLabelProps={{
                  style: {
                    color: "rgba(229, 229, 229, 0.5)",
                  },
                }}
                InputProps={{
                  style: {
                    color: "rgba(216, 216, 216, 1)",
                    borderColor: "rgba(229, 229, 229, 0.5)",
                    borderRadius: "50px",
                  },
                }}
                sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "50px",
                      color: "rgba(216, 216, 216, 1)",
                
                      "& fieldset": {
                        borderColor: "rgba(229, 229, 229, 0.5)",
                      },
                      "&:hover fieldset": {
                        borderColor: "rgba(229, 229, 229, 0.75)",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "rgba(229, 229, 229, 1)",
                      },
                      "& .MuiSvgIcon-root": {
                        color: "rgba(216, 216, 216, 1) !important",
                      },
                  
                      "& .MuiIconButton-root": {
                        color: "rgba(216, 216, 216, 1) !important",
                      },
                  },
                }}
              />
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div class="w-5 h-5 bg-[#069855] rounded-sm mr-1.5"></div>
              <span class="text-sm text-[#069855]">Complete</span>
            </div>
            <div class="flex items-center ml-2.5">
              <div class="w-5 h-5 bg-[rgba(243,200,102,1)] rounded-sm mr-1.5"></div>
              <span class="text-sm text-[#D39C1D]">In Progress</span>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
              <div class="w-5 h-5 bg-[#D9D9D9] rounded-sm mr-1.5"></div>
              <span class="text-sm text-[#D9D9D9]">Pending</span>
            </div>
          </div>
        </div>
        <Table  >
          <Thead>
            <Tr >
              <Th style={{ textAlign: 'left', paddingLeft:80 }}>Posting Date</Th>
              <Th style={{ textAlign: 'left' }}>Job Role</Th>
              <Th style={{ textAlign: 'center' }}>Total Candidates</Th>
              <Th style={{ textAlign: 'center' }}>Progress</Th>
            </Tr>
          </Thead>
          <Tbody >
            {filteredJobPositions.map((job, index) => (
              <Tr key={index}>
                <Td style={{ textAlign: 'left', paddingLeft:80 }}>{job.posting_date}</Td>
                <Td style={{ textAlign: 'left', paddingLeft:25 }}>{job.job_role.toLowerCase()
                            .replace(/\b\w/g, (char) => char.toUpperCase()) ||
                            "Unknown"}</Td>
                <Td class="text-center">{job.scheduled_candidates}</Td>
                <Td>
                  <SegmentedProgressBar
                    processComplete={job.process_complete_candidates}
                    inProgress={job.in_progress_candidates}
                    testPending={job.test_completion_pending}
                    total={job.scheduled_candidates}
                  />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        <div className="table-footer" style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div className="pagination-text">
            Showing <span className="font-medium">1</span> to <span className="font-medium">{filteredJobPositions.length}</span> of <span className="font-medium">{filteredJobPositions.length}</span> results
          </div>
          <Pagination count={1} />
        </div>
      </div>
    </div>
    </div>
  );
}
