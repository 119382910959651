import React from "react";
import { useState, useRef } from "react";
import {
  Mic,
  ShieldAlert,
  Headphones,
  Video,
  ArrowRight,
  CheckCircle,
  AlertCircle,
} from "lucide-react";
import host from "../../global";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const InterviewInstructions = () => {
  const [isHovered, setIsHovered] = useState(false);
  const { interview_id } = useParams();
  const navigate = useNavigate();
  const [isChecking, setIsChecking] = useState(false);
  const [isMicWorking, setIsMicWorking] = useState(null);
  const [error, setError] = useState(null);
  const audioContext = useRef(null);
  const analyzer = useRef(null);
  const mediaStream = useRef(null);
  const [isChecked, setIsChecked] = useState(false);

  const startMicTest = async () => {
    try {
      setError(null);
      setIsChecking(true);
      setIsMicWorking(null);

      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaStream.current = stream;
      audioContext.current = new AudioContext();
      const source = audioContext.current.createMediaStreamSource(stream);
      analyzer.current = audioContext.current.createAnalyser();
      analyzer.current.fftSize = 2048;
      source.connect(analyzer.current);

      const bufferLength = analyzer.current.frequencyBinCount;
      const dataArray = new Uint8Array(bufferLength);
      let startTime = Date.now();
      let hasDetectedSound = false;

      const checkAudio = () => {
        if (!analyzer.current) return;

        analyzer.current.getByteFrequencyData(dataArray);
        const average = dataArray.reduce((a, b) => a + b) / bufferLength;

        if (average > 30) {
          hasDetectedSound = true;
        }

        if (Date.now() - startTime > 3000) {
          setIsChecking(false);
          setIsMicWorking(hasDetectedSound);
          cleanup();
          return;
        }
        requestAnimationFrame(checkAudio);
      };
      checkAudio();
    } catch (err) {
      setError("Please allow microphone access to test your mic");
      setIsChecking(false);
      console.error("Error accessing microphone:", err);
    }
  };

  const cleanup = () => {
    if (mediaStream.current) {
      mediaStream.current.getTracks().forEach((track) => track.stop());
    }
    if (audioContext.current) {
      audioContext.current.close();
    }
    mediaStream.current = null;
    audioContext.current = null;
    analyzer.current = null;
  };

  useEffect(() => {
    return cleanup;
  }, []);

  const handleProceed = async () => {
    await fetch(`${host}/greet_user/${interview_id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });
    navigate(`/candidate/interview/${interview_id}`);
  };

  const instructions = [
    {
      icon: <Mic className="w-6 h-6" />,
      text: "Ensure your microphone is working properly and test it before starting the interview.",
    },
    {
      icon: <Headphones className="w-6 h-6" />,
      text: "Use headphones or a headset to avoid audio distractions and ensure clear communication.",
    },
    {
      icon: <ShieldAlert className="w-6 h-6" />,
      text: "Do not attempt to access unauthorized resources or collaborate with others during the interview.",
    },
    {
      icon: <Video className="w-6 h-6" />,
      text: "The interview will be recorded; ensure your environment and behavior adhere to professional standards.",
    },
  ];

  return (
    <div className="min-h-screen">
      <div className="h-screen flex">
        {/* Left Section */}
        <div className="w-1/2 py-8 pr-4 pl-8" >
          <div className="rounded-2xl w-full max-w-2xl mx-auto flex flex-col items-center relative overflow-hidden">
            {/* Main content */}
            <div className="relative z-10 w-full max-w-2xl p-8 glass-effect backdrop-blur-lg rounded-2xl shadow-xl">
              <h1 className="text-3xl font-bold mb-6 mt-8 text-center bg-gradient-to-r from-[#69007f] to-[#fc0987] text-transparent bg-clip-text">
                System Check
              </h1>

              <p className="text-gray-300 text-center mb-8">
                Let's ensure everything is set up properly for your interview
              </p>

              {/* Microphone test section */}
              <div className="px-6 py-2 h-full w-full">
                <div className="space-y-4">
                  {!isChecking && isMicWorking === null && (
                    <div className="space-y-6">
                      <div>
                        <h3 className="text-lg font-semibold mb-2 text-gray-100 ">
                          Quick Setup:
                        </h3>
                        <ul className="space-y-2 text-gray-300">
                          <li>✓ Find a quiet environment</li>
                          <li>✓ Check microphone connection</li>
                          <li>✓ Prepare to speak clearly</li>
                        </ul>
                      </div>
                    </div>
                  )}

                  {isChecking && (
                    <div className="text-center animate-pulse space-y-4">
                      <Mic className="mx-auto h-12 w-12 text-pink-500" />
                      <p className="text-lg text-pink-600">Listening...</p>
                      <p className="text-gray-300">
                        Please speak clearly: "I am ready to give the interview"
                      </p>
                    </div>
                  )}

                  {!isChecking && isMicWorking === true && (
                    <div className="text-center text-green-600 space-y-4">
                      <CheckCircle className="mx-auto h-12 w-12" />
                      <p className="text-lg">
                        Perfect! Your microphone is working properly
                      </p>
                      <p className="text-gray-300">
                        You're all set to begin your interview
                      </p>
                    </div>
                  )}

                  {!isChecking && isMicWorking === false && (
                    <div className="text-red-600 space-y-4 px-4">
                      <AlertCircle className="mx-auto h-12 w-12" />
                      <p className="text-center text-lg">No audio detected</p>
                      <div className="text-gray-300 text-sm space-y-2">
                        <p>Please check:</p>
                        <ul className="list-disc list-inside">
                          <li>Your microphone is properly connected</li>
                          <li>You've allowed browser microphone access</li>
                          <li>Your microphone isn't muted</li>
                        </ul>
                      </div>
                      <div className="p-4 mt-0">
                        <button
                          onClick={startMicTest}
                          className="px-8 py-3 button text-white font-semibold rounded-lg transform transition-all duration-300 hover:scale-105 hover:shadow-lg flex items-center gap-2 "
                        >
                          <span> Retry Test</span>
                        </button>
                      </div>
                    </div>
                  )}

                  {!isChecking && isMicWorking === null && (
                    <div className="py-4 mt-0">
                      <button
                        onClick={startMicTest}
                        className="button px-8 py-3"
                      >
                        <span>Start Mic Test</span>
                      </button>
                    </div>
                  )}

                  {error && (
                    <div className="text-red-600 space-y-2 mt-4">
                      <AlertCircle className="mx-auto h-8 w-8" />
                      <p className="text-sm">{error}</p>
                      <p className="text-xs text-gray-300">
                        If the error persists, try refreshing your browser
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Right Section */}
        <div className="w-1/2 py-8 pl-4 pr-8">
          <div className="max-w-2xl mx-auto">
            <div className="glass-effect backdrop-blur-lg rounded-2xl p-8 shadow-xl">
              <h2 className="text-3xl font-bold text-center mb-8 bg-gradient-to-r from-[#69007f] to-[#fc0987] text-transparent bg-clip-text">
                Test Instructions
              </h2>

              <div className="space-y-4">
                {instructions.map((instruction, index) => (
                  <div
                    key={index}
                    className="flex items-center gap-4 p-2 rounded-lg"
                  >
                    <div className="text-pink-500 mt-1 flex-shrink-0">
                      {instruction.icon}
                    </div>
                    <p className="text-gray-300">{instruction.text}</p>
                  </div>
                ))}
              </div>
              <div className="flex mt-8 justify-between">
                <div className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    id="understandCheckbox"
                    checked={isChecked}
                    onChange={(e) => setIsChecked(e.target.checked)}
                    className="w-4 h-4"
                  />
                  <label htmlFor="understandCheckbox" className="text-gray-300">
                    I understand and agree
                  </label>
                </div>
                <div className="text-center">
                  <button
                    onClick={handleProceed}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                    disabled={!isChecked}
                    className={`button group relative px-8 py-3 bg-gradient-to-r from-[#3ECFDA] to-[#0952DA] text-white font-semibold rounded-lg transform transition-all duration-300 hover:scale-105 hover:shadow-lg flex items-center gap-2`}
                    style={{
                      opacity: !isChecked ? 0.5 : 1,
                      cursor: !isChecked ? 'not-allowed' : 'pointer',
                    }}
                  >
                    <span className="flex gap-2">
                    Proceed to Test
                    <ArrowRight
                      className={`w-5 h-5 transition-transform duration-300 ${
                        isHovered ? "translate-x-1" : ""
                      }`}
                    />
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InterviewInstructions;
