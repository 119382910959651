import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import host from "../../global.jsx";
import {
  BrainCog,
  Code2,
  Users,
  Rocket,
  Trophy,
  Timer,
  Lock,
  CheckCircle,
  ChevronRight,
  Target,
} from "lucide-react";
import { toast } from "react-toastify";
export default function Component() {
  const navigate = useNavigate();
  const { interview_id } = useParams();
  const [mcqStatus, setMCQStatus] = useState("Incomplete");
  const [codingStatus, setCodingStatus] = useState("Incomplete");
  const [interviewStatus, setInterviewStatus] = useState("Incomplete");
  const [mcqTimeLimit, setMCQTimeLimit] = useState(0);
  const [codingTimeLimit, setCodingTimeLimit] = useState(0);
  const [interviewTimeLimit, setInterviewTimeLimit] = useState(0);
  const [assessmentSequence, setAssessmentSequence] = useState([]);
  const totalAssessments = [mcqStatus, codingStatus, interviewStatus].filter(
    (status) => status !== "NA"
  ).length;
  const progress = [
    mcqStatus === "Complete" || mcqStatus === "Passed",
    codingStatus === "Complete",
    interviewStatus === "Complete" || interviewStatus === "Passed",
  ].filter(Boolean).length;

  useEffect(() => {
    const checkStatus = async () => {
      try {
        const response = await axios.get(
          `${host}/check_test_progress/${interview_id}`
        );
        if (response) {
          setMCQStatus(response.data.mcq_status);
          setCodingStatus(response.data.coding_status);
          setInterviewStatus(response.data.interview_status);
          setMCQTimeLimit(response.data.mcq_time_limit);
          setCodingTimeLimit(response.data.coding_time_limit);
          setInterviewTimeLimit(response.data.interview_time_limit);
          setAssessmentSequence(response.data.assessment_sequence);
        }
      } catch (error) {
        console.error(error);
      }
    };
    checkStatus();
  }, [interview_id]);

  const navigateMCQtest = async () => {
    try {
      const response = await axios.get(`${host}/check_test_progress/${interview_id}`);
      const currentIndex = assessmentSequence.indexOf('mcq');
      const previousType = currentIndex > 0 ? assessmentSequence[currentIndex - 1] : null;

      const canStartMCQ = !previousType || (
        previousType === 'coding' ? ['Complete', 'NA'].includes(response.data.coding_status) :
          previousType === 'interview' ? ['Complete', 'NA'].includes(response.data.interview_status) : true
      );

      if (canStartMCQ && response.data.mcq_status === "Incomplete") {
        localStorage.setItem(
          `timeLeft_${interview_id}_mcq`,
          response.data.mcq_time_limit * 60
        );
        setTimeout(() => {
          navigate(`/candidate/${interview_id}/mcqinstructions`);
        }, 1000);
      } else if (!canStartMCQ) {
        toast.warn("Please complete the previous assessment first!");
      } else {
        toast.warn("MCQ Test is already completed!");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const navigateCodingtest = async () => {
    try {
      const response = await axios.get(`${host}/check_test_progress/${interview_id}`);
      const currentIndex = assessmentSequence.indexOf('coding');
      const previousType = currentIndex > 0 ? assessmentSequence[currentIndex - 1] : null;

      const canStartCoding = !previousType || (
        previousType === 'mcq' ? ['Passed', 'NA'].includes(response.data.mcq_status) :
          previousType === 'interview' ? ['Complete', 'NA'].includes(response.data.interview_status) : true
      );

      if (canStartCoding && response.data.coding_status !== "Complete") {
        localStorage.setItem(
          `timeLeft_${interview_id}_coding`,
          response.data.coding_time_limit * 60
        );
        setTimeout(() => {
          navigate(`/candidate/${interview_id}/codinginstructions`);
        }, 1000);
      } else if (!canStartCoding) {
        toast.warn("Please complete the previous assessment first!");
      } else {
        toast.error("Coding Test is already completed!");
      }
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  const navigateInterview = async () => {
    try {
      const response = await axios.get(`${host}/check_test_progress/${interview_id}`);
      const currentIndex = assessmentSequence.indexOf('interview');
      const previousType = currentIndex > 0 ? assessmentSequence[currentIndex - 1] : null;

      const canStartInterview = !previousType || (
        previousType === 'mcq' ? ['Passed', 'NA'].includes(response.data.mcq_status) :
          previousType === 'coding' ? ['Complete', 'NA'].includes(response.data.coding_status) : true
      );

      if (canStartInterview) {
        navigate(`/candidate/${interview_id}/interviewinstructions`);
      } else {
        toast.warn("Please complete the previous assessment first!");
      }
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  const renderAssessmentCard = (type, index) => {

    const validAssessments = assessmentSequence.filter(assessType => {
      if (assessType === 'mcq') return mcqStatus !== 'NA';
      if (assessType === 'coding') return codingStatus !== 'NA';
      if (assessType === 'interview') return interviewStatus !== 'NA';
      return false;
    });

    const stepNumber = validAssessments.indexOf(type) + 1;

    const cardProps = {
      mcq: {
        title: "Knowledge Assessment",
        icon: BrainCog,
        description: "Begin our adaptive assessment to test your knowledge.",
        onNavigate: navigateMCQtest,
        status: mcqStatus,
      },
      coding: {
        title: "Coding Challenge",
        icon: Code2,
        description: "Solve real-world problems to showcase your coding skills.",
        onNavigate: navigateCodingtest,
        status: codingStatus,
      },
      interview: {
        title: "Technical Interview",
        icon: Users,
        description: "Join an AI interview to discuss your expertise.",
        onNavigate: navigateInterview,
        status: interviewStatus,
      }
    };

    const { title, icon, description, onNavigate, status } = cardProps[type];

    if (status === "NA") return null;

    return (
      <CardComponent
        key={type}
        title={title}
        icon={icon}
        description={description}
        onNavigate={onNavigate}
        status={status}
        stepNumber={stepNumber}
        validAssessments={validAssessments}
      />
    );
  };

  function formatTime(minutes) {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return hours > 0
      ? `${String(hours).padStart(2, "0")} H ${String(mins).padStart(
        2,
        "0"
      )} mins`
      : `${String(mins).padStart(2, "0")} mins`;
  }

  const CardComponent = ({
    title,
    icon: Icon,
    onNavigate,
    description,
    status,
    stepNumber,
    validAssessments
  }) => {
    const isCompleted = status === "Complete" || status === "Passed";

    const getCurrentType = () => {
      if (title === "Knowledge Assessment") return "mcq";
      if (title === "Coding Challenge") return "coding";
      return "interview";
    };

    const checkIsLocked = () => {
      const currentType = getCurrentType();
      const currentIndex = validAssessments.indexOf(currentType);

      if (currentIndex === 0) return false;

      const previousType = validAssessments[currentIndex - 1];
      if (!previousType) return false;

      return previousType === 'mcq' ? !['Passed', 'NA'].includes(mcqStatus) :
        previousType === 'coding' ? !['Complete', 'NA'].includes(codingStatus) :
          previousType === 'interview' ? !['Complete', 'NA'].includes(interviewStatus) : false;
    };

    // const shouldShowStartButton = () => {
    //   const currentType = getCurrentType();
    //   const currentIndex = validAssessments.indexOf(currentType);

    //   // Always show button for first assessment
    //   if (currentIndex === 0) return true;

    //   // If not first assessment, check if previous assessment was passed
    //   const previousType = validAssessments[currentIndex - 1];
    //   if (!previousType) return false;

    //   // Check previous assessment status
    //   const previousPassed =
    //     previousType === 'mcq' ? mcqStatus === 'Passed' :
    //       previousType === 'coding' ? codingStatus === 'Complete' :
    //         previousType === 'interview' ? interviewStatus === 'Complete' : false;

    //   return previousPassed && !isCompleted;
    // };


    const shouldShowStartButton = () => {
      const currentType = getCurrentType();
      const currentIndex = validAssessments.indexOf(currentType);
      
      // For first assessment, show button only if not completed
      if (currentIndex === 0) {
        return !isCompleted; // Hide button if completed
      }
      
      // For subsequent assessments
      const previousType = validAssessments[currentIndex - 1];
      if (!previousType) return false;
      
      // Check previous assessment status
      const previousPassed = 
        previousType === 'mcq' ? mcqStatus === 'Passed' :
        previousType === 'coding' ? codingStatus === 'Complete' :
        previousType === 'interview' ? interviewStatus === 'Complete' : false;
      
      // Show button only if previous is passed AND current is not completed
      return previousPassed && !isCompleted;
    };

    const isLocked = checkIsLocked();
    const isInProgress = !isLocked && !isCompleted;
    const showStartButton = shouldShowStartButton();

    return (
      <div
        className={`
        relative rounded-3xl transition-all duration-500
        ${!isInProgress
            ? "bg-gray-500 opacity-75"
            : "cardPattern shadow-md"
          }
      `}
      >
        <div className="absolute -left-6 top-1/2 -translate-y-1/2 flex items-center">
          <div
            className={`
            w-12 h-12 rounded-full flex items-center justify-center text-md font-bold
            ${isCompleted
                ? "bg-green-400 text-white"
                : isInProgress
                  ? "bg-gradient-to-r from-[#69007f] to-[#fc0987] text-white"
                  : "bg-gray-200 text-gray-500"
              }
          `}
          >
            {isCompleted ? <CheckCircle className="w-6 h-6" /> : stepNumber}
          </div>
        </div>

        {stepNumber < validAssessments.length && (
          <div
            className={`absolute -bottom-12 left-0 w-[2px] h-12 -z-10 ${isCompleted ? "bg-[#4ade80]" : "bg-gray-200"
              }`}
            style={{ left: "19px" }}
          />
        )}

        <div className=" p-4 pl-12 h-full">
          <div className="flex items-start justify-between gap-10">
            <div className="flex-1">
              <div className="flex items-center gap-4 mb-4">
                <div
                  className={`
                  p-4 rounded-2xl
                  
                `}
                >
                  <Icon className="w-9 h-9 text-pink-500" />
                </div>
                <div>
                  <h2
                    className={`
                    text-xl font-bold font-['Poppins'] tracking-tight
                    ${!isInProgress ? "text-gray-400" : "text-white"}
                  `}
                  >
                    {title}
                  </h2>
                  <div className="flex items-center gap-2 mt-1">
                    {!isInProgress && (
                      <Lock className="w-4 h-4 text-gray-400" />
                    )}
                    <span
                      className={`
                      text-sm font-medium
                      ${isCompleted
                          ? "text-green-500"
                          : isInProgress
                            ? "text-pink-500"
                            : "text-gray-400"
                        }
                    `}
                    >
                      {isCompleted
                        ? "Completed"
                        : isInProgress
                          ? "In Progress"
                          : "Locked"}
                    </span>
                  </div>
                </div>
              </div>
              <p
                className={`
                text-md
                ${!isInProgress ? "text-gray-400" : "text-gray-300"}
              `}
              >
                {description}
              </p>
            </div>
            {showStartButton && (
              <div className="flex items-center justify-center h-full">
                <button
                  onClick={onNavigate}
                  disabled={!isInProgress}
                  className={`
                  px-6 py-3 rounded-xl font-semibold transition-all duration-300 
                  flex items-center gap-2 button
                  ${!isInProgress
                      ? "bg-gray-200 text-gray-400 cursor-not-allowed"
                      : "bg-gradient-to-r from-[#3ECFDA] to-[#0952DA] text-white hover:gap-4"
                    }
                `}
                >
                  <span className="flex gap-2">
                    Start Now
                    <ChevronRight className="w-5 h-5" />
                  </span>
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="max-h-screen h-full overflow-auto px-8 py-8">
      <div className="flex flex-wrap justify-between items-start">
        {/* Left Column - Stats */}
        <div className="w-full lg:w-5/12 space-y-8 py-4">
          <div className="p-6 shadow-xl rounded-3xl shadow-xl p-4 text-white relative overflow-hidden cardPattern">
            <div className="relative z-10 space-y-6">
              <div className="flex items-center gap-3">
                <Rocket className="w-6 h-6 text-pink-500" />
                <h1 className="text-2xl font-bold">Assessment Flow</h1>
              </div>

              <p className="text-lg opacity-90">
                Complete each step to unlock the next challenge
              </p>

              <div className="grid grid-cols-1 gap-4">
                {(mcqStatus === "NA" || codingStatus === "NA" || mcqStatus === "Passed" || codingStatus === "Complete" || mcqStatus === "Incomplete") ? (
                  <>
                    <div className="backdrop-blur-lg rounded-2xl p-4 m-4 shadow-lg">
                      <div className="flex items-center gap-4">
                        <Trophy className="w-6 h-6 mb-1" />
                        <div className="text-2xl font-semibold text-white">
                          {progress}/{totalAssessments}
                        </div>
                      </div>
                      <div className="text-sm text-white opacity-90 mt-2">
                        {progress !== totalAssessments ? (
                          <span>Progress</span>
                        ) : (
                          <span className="text-black-400">
                            All assessments are complete!
                          </span>
                        )}
                      </div>
                      <div className="w-full h-1 mt-4 bg-gray-300 rounded-full">
                        <div
                          className="h-full bg-green-500 rounded-full"
                          style={{
                            width: `${(progress / totalAssessments) * 100}%`,
                          }}
                        ></div>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="max-w-md mx-auto transform">
                    <div className="relative overflow-hidden rounded-2xl shadow-xl">
                      {/* Gradient border effect using pseudo-element */}
                      <div className="absolute inset-0" />

                      {/* Inner content with glass effect */}
                      <div className="relative m-[2px] p-8 rounded-2xl">
                        {/* Decorative elements */}
                        <div className="absolute top-0 left-0 w-24 h-24 bg-[#3ECFDA] rounded-full opacity-10 blur-2xl" />
                        <div className="absolute bottom-0 right-0 w-32 h-32 bg-[#0952DA] rounded-full opacity-10 blur-2xl" />

                        {/* Content */}
                        <div className="relative z-10 text-center">
                          <h2 className="text-lg font-bold text-white">
                            Unfortunately, Your Journey Ends Here!
                          </h2>
                          <p className="mt-4 text-xs text-white-400 opacity-75  text-center">
                            Keep growing! Every end is a new beginning ✨
                          </p>
                          <div className="mt-4 h-[2px] w-16 mx-auto bg-gradient-to-r from-[#3ECFDA] to-[#0952DA] rounded-full" />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* Decorative elements */}
            <div className="absolute top-0 right-0 w-64 h-64 bg-white/10 rounded-full blur-3xl -translate-y-1/2 translate-x-1/2" />
            <div className="absolute -bottom-8 -left-8 w-48 h-48 bg-white/10 rounded-full blur-2xl" />
          </div>

          {/* Progress Tracker */}
          <div className="backdrop-blur-sm rounded-3xl p-6 shadow-lg cardPattern">
            <div className="flex items-center gap-3 mb-6">
              <Target className="w-6 h-6 text-pink-500" />
              <h2 className="text-xl font-semibold text-white">
                Assessment Details
              </h2>
            </div>
            <div className="space-y-4">
              {mcqStatus !== "NA" && (
                <div className="flex items-center gap-2">
                  <div className="flex-1">
                    <div className="text-sm font-medium text-white">
                      Knowledge Assessment
                    </div>
                  </div>
                  <div className="flex items-center gap-1">
                    <Timer className="w-4 h-4 text-gray-500" />
                    <span className="text-xs text-gray-500">
                      {formatTime(mcqTimeLimit)}
                    </span>
                  </div>
                </div>
              )}
              {codingStatus !== "NA" && (
                <div className="flex items-center gap-2">
                  <div className="flex-1">
                    <div className="text-sm font-medium text-gray-400">
                      Coding Challenge
                    </div>
                  </div>
                  <div className="flex items-center gap-1">
                    <Timer className="w-4 h-4 text-gray-400" />
                    <span className="text-xs text-gray-400">
                      {formatTime(codingTimeLimit)}
                    </span>
                  </div>
                </div>
              )}
              <div className="flex items-center gap-2">
                <div className="flex-1">
                  <div className="text-sm font-medium text-gray-400">
                    Technical Interview
                  </div>
                </div>
                <div className="flex items-center gap-1">
                  <Timer className="w-4 h-4 text-gray-400" />
                  <span className="text-xs text-gray-400">
                    {formatTime(interviewTimeLimit)}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Right Column - Assessment Cards */}
        <div className="space-y-8 lg:w-6/12 relative pl-6 py-4">
          {assessmentSequence.map((type, index) =>
            renderAssessmentCard(type, index)
          )}
        </div>
      </div>
    </div>
  );
}
