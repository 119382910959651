import { Code, Brain, Users } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const TestLibrary = () => {
  const navigate = useNavigate();

  const testCategories = [
    {
      id: 1,
      title: 'MCQ Tests',
      type: 'mcq',
      icon: Brain,
      description: 'Multiple choice questions covering various technical topics, aptitude, and general knowledge.',
      features: ['Instant results', 'Comprehensive feedback', 'Practice mode available'],
      action: () => navigate('/org-admin/createmcqset')
    },
    {
      id: 2,
      title: 'Coding Tests',
      type: 'coding',
      icon: Code,
      description: 'Hands-on programming challenges to assess technical skills and problem-solving abilities.',
      features: ['Multiple programming languages', 'Real-time compilation', 'Custom test cases'],
      action: () => navigate('/org-admin/createcodingproblemset')
    },
    {
      id: 3,
      title: 'Behavioral & Soft Skills',
      type: 'behavioral',
      icon: Users,
      description: 'Evaluate communication, teamwork, leadership, and other essential soft skills.',
      features: ['Scenario-based questions', 'Personality assessments', 'Communication skills evaluation'],
      comingSoon: true
    }
  ];

  const CategoryCard = ({ category }) => {
    const IconComponent = category.icon;
    
    return (
        <div className="bg-white/5 rounded-2xl p-6 transition-all duration-300 hover:scale-105 hover:shadow-md hover:border-blue-300 cursor-pointer">
        <div className="flex flex-col items-center text-center mb-6">
          <div className="p-4 rounded-full mb-4 text-[rgba(240,248,255,0.691)]">
            <IconComponent className="w-8 h-8" />
          </div>
          <h3 className="text-xl font-semibold text-[rgba(240,248,255,0.691)] mb-2">
            {category.title}
          </h3>
          <p className="text-[rgba(240,248,255,0.691)] text-sm mb-6">
            {category.description}
          </p>
        </div>
        
        <div className="space-y-3">
          {category.features.map((feature, index) => (
            <div key={index} className="flex items-center">
              <div className="w-2 h-2 bg-blue-300 rounded-full mr-2"></div>
              <span className="text-sm text-[rgba(240,248,255,0.691)]">{feature}</span>
            </div>
          ))}
        </div>
        
        <button 
          className="button w-full mt-6"
          onClick={category.action}
          disabled={category.comingSoon}
        >
          <span>
            {category.comingSoon ? 'Coming Soon' : 'Manage Tests'}
          </span>
        </button>
      </div>
    );
  };


  return (
    <div className="min-h-screen">
      <div className="p-8 responsive-container">        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {testCategories.map(category => (
            <CategoryCard key={category.id} category={category} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default TestLibrary;