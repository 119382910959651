import React, { useState, useEffect } from "react";
import axios from "axios";
import host from "../../global";
import { Authenticator } from "@aws-amplify/ui-react";
import { Button, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Upload, FileText, Scan, CheckCircle2 } from "lucide-react";
import {
  ChevronRight,
} from "lucide-react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography
} from '@mui/material';
import {
  CheckCircle,
} from "lucide-react";
import {
  Skeleton,
} from "@mui/material";
const BulkResumeUpload = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [jobPositions, setJobPositions] = useState({});
  const [selectedJobPosition, setSelectedJobPosition] = useState("");
  const [selectedID, setSelectedID] = useState("");
  const [jobDescription, setJobDescription] = useState("");
  const [resume, setResume] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [resumeName, setResumeName] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const accessToken = Cookies.get('accessToken');
  const [selectedInterviewType, setSelectedInterviewType] = useState('');
  const [interview_timelimit, setInterviewTimeLimit] = useState(0);
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleExpand = () => {
    setIsExpanded((prev) => !prev);
  };
  useEffect(() => {
    const fetchJobPositions = async () => {

      try {
        const response = await axios.get(`${host}/getjdpositions`, {
          headers: {
            Authorization: `Bearer ${accessToken.toString()}`
          }
        });
        setJobPositions(response.data);

      } catch (error) {
        if (error.response) {
          const status = error.response.status;
          const detail = error.response.data.detail;
          if (status === 401) {
            await new Promise((resolve) => {
              Cookies.remove('accessToken');
              resolve();
            });
            toast.error(`Unauthorized: ${detail}`);
          } else if (status === 404) {
            toast.warning(`Not Found: ${detail}`);
          } else {
            toast.error(`Error: ${detail}`);
          }
        } else {
          toast.error('An unexpected error occurred. Please try again.');
        }
      }
    };
    fetchJobPositions();
  }, [accessToken]);

  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files[0];
    setResume(droppedFile);
    setResumeName(droppedFile.name);
    handleResumeChange({ target: { files: [droppedFile] } });
  };
  const handleDragOver = (event) => {
    event.preventDefault();
  };
  const handleResumeChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileName = file.name;
      const fileExtension = fileName.split(".").pop().toLowerCase();
      
      // Check for spaces or special characters in the filename
      if (fileName.includes(" ") || /[()]/.test(fileName)) {
        setErrorMessage("Filename should not contain spaces or special characters. Please rename the file.");
        setResume(null);
        setResumeName("");
        toast.error("Filename should not contain spaces or special characters. Please rename the file.");
        return;
      }
  
      if (fileExtension === "zip") {
        setErrorMessage("");
        setResume(file);
        setResumeName(fileName);
        console.log("Valid ZIP file selected:", file);
      } else {
        setErrorMessage("Only ZIP files are allowed.");
        setResume(null);
        setResumeName("");
        toast.error("Only ZIP files are allowed.");
        console.log("Invalid file type selected");
      }
    } else {
      setErrorMessage("Please select a file.");
      setResume(null);
      setResumeName("");
      toast.error("Please select a file.");
    }
  };  
    
  const handleJobPositionChange = async (e) => {
    const selectedPosition = jobPositions[e.target.value];
    setSelectedID(e.target.value)
    setSelectedJobPosition(selectedPosition.job_role);
    setJobDescription("");
    setIsSubmitting(false);
    setIsLoading(true);
    try {
      const response = await axios.get(`${host}/getjd`, {
        headers: {
          Authorization: `Bearer ${accessToken.toString()}`
        },
        params: {
          jd_id: e.target.value,
        },
      });
      if (response) {
        setJobDescription(response.data.description);
        setInterviewTimeLimit(response.data.interview_timelimit);
        setSelectedInterviewType(response.data.interview_type);
      }
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        const detail = error.response.data.detail;
        if (status === 401) {
          await new Promise((resolve) => {
            Cookies.remove('accessToken');
            resolve();
          });
          toast.error(`Unauthorized: ${detail}`);
        } else if (status === 404) {
          toast.warning(`Not Found: ${detail}`);
        } else {
          toast.error(`Error: ${detail}`);
        }
      } else {
        toast.error('An unexpected error occurred. Please try again.');
      }
      setJobDescription('');
    }
    finally {
      setIsLoading
        (false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedJobPosition || !jobDescription) {
      toast.error('Please select a job position and load the job description.');
      return;
    }
    if (!resume) {
      toast.error('Please upload a resume.');
      return;
    }
    setIsSubmitting(true);
    const formData = new FormData();
    formData.append('interview_type', selectedInterviewType);
    formData.append('time_limit', interview_timelimit);
    formData.append('job_role', selectedJobPosition);
    formData.append('job_description', JSON.stringify(jobDescription));
    formData.append('jd_id', selectedID)
    formData.append('file', resume);
    try {
      axios.post(`${host}/Bulkupload`, formData, {
        headers: {
          Authorization: `Bearer ${accessToken.toString()}`
        }
      });
      setIsSubmitting(false);
      toast.success("Bulk Resume Upload Successfull!")
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        const detail = error.response.data.detail;
        if (status === 401) {
          await new Promise((resolve) => {
            Cookies.remove('accessToken');
            resolve();
          });
          toast.error(`Unauthorized: ${detail}`);
        } else if (status === 404) {
          toast.warning(`Not Found: ${detail}`);
        } else {
          toast.error(`Error: ${detail}`);
        }
      } else {
        toast.error('An unexpected error occurred. Please try again.');
      }
    } finally {
      setIsSubmitting(false);
    }
  };
  const formatJobDescription = (data) => {
    let result = "";
    if (data.minExp !== null && data.minExp !== undefined && data.maxExp !== null && data.maxExp !== undefined) {
      result += `Experience Range: ${data.minExp} - ${data.maxExp}\n\n`;
    }
    const Experience_Level = `${(data.minExp + data.maxExp)/2}`
    const isMediumSkillsRequired = Experience_Level < 4;
    if (data.Required_Skills) {
      if (data.Required_Skills.Hard && data.Required_Skills.Hard.length > 0) {
        result += `Required Skills - Hard:\n`;
        data.Required_Skills.Hard.forEach((item, index) => {
          result += `  ${index + 1}. ${item.join(", ")}\n`;
        });
        result += "\n";
      }
      if (
        data.Required_Skills.Medium &&
        Array.isArray(data.Required_Skills.Medium) &&
        data.Required_Skills.Medium.length > 0
      ) {
        if (isMediumSkillsRequired) {
          result += `Required Skills - Medium:\n`;
          data.Required_Skills.Medium.forEach((item, index) => {
            result += `  ${index + 1}. ${item.join(", ")}\n`;
          });
          result += "\n";
        }
      }
    }
    if (data.Required_Skills) {
      if (data.Required_Skills.Easy && data.Required_Skills.Easy.length > 0) {
        result += `Optional Skills - Easy:\n`;
        data.Required_Skills.Easy.forEach((item, index) => {
          result += `  ${index + 1}. ${item.join(", ")}\n`;
        });
        result += "\n";
      }
      if (
        data.Required_Skills.Medium &&
        !isMediumSkillsRequired &&
        data.Required_Skills.Medium.length > 0
      ) {
        result += `Optional Skills - Medium:\n`;
        data.Required_Skills.Medium.forEach((item, index) => {
          result += `  ${index + 1}. ${item.join(", ")}\n`;
        });
        result += "\n";
      }
    }
    if (data.Roles_and_Responsibilities && data.Roles_and_Responsibilities.length > 0) {
      result += `Roles and Responsibilities:\n`;
      data.Roles_and_Responsibilities.forEach((item, index) => {
        result += `  - ${item}\n`;
      });
      result += "\n";
    }
    if (data.GoodtoHave && data.GoodtoHave.length > 0) {
      result += `Good to Have:\n  - ${data.GoodtoHave.join(", ")}\n\n`;
    }
    if (data.Tools_and_Technologies && data.Tools_and_Technologies.length > 0) {
      result += `Tools and Technologies:\n`;
      data.Tools_and_Technologies.forEach((item, index) => {
        result += `  - ${item}\n`;
      });
      result += "\n";
    }
    return result;
  };
  const formattedDescription = formatJobDescription(jobDescription);
  return (
    <Authenticator>
      <div className="min-h-screen">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
          <div className="glass-effect rounded-3xl shadow-xl p-4 border border-white/20">
            <div className="flex flex-col gap-8">
            <div
                      className="space-y-6 w-full relative border-2 border-dashed border-gray-300 rounded-2xl p-4 cursor-pointer "
                      onClick={() =>
                        document.getElementById("fileInput")?.click()
                      }
                      onDrop={handleDrop}
                      onDragOver={handleDragOver}
                    >
                      {resume ? (
                        <div className="transform transition-all duration-500 ease-in-out">
                          <div className="w-32 h-32 mx-auto rounded-full bg-gradient-to-br from-green-400 to-teal-500 flex items-center justify-center shadow-xl shadow-green-500/30 animate-bounce-small">
                            <CheckCircle className="w-16 h-16 text-white animate-scale-check" />
                          </div>
                          <div className="space-y-4 mt-6">
                            <h3 className="text-2xl font-bold text-white text-center animate-fade-in">
                              Resume Successfully Uploaded!
                            </h3>
                            <div className="flex items-center justify-center space-x-2">
                              <FileText className="w-5 h-5 text-white" />
                              <p className="text-white font-medium">
                                {resumeName}
                              </p>
                            </div>
                            <div className="text-center bg-green-100 rounded-lg p-3 animate-fade-in">
                              <p className="text-green-700 text-sm">
                                Your resume is ready for AI analysis
                              </p>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <>
                          <div className="w-32 h-32 mx-auto rounded-full bg-gradient-to-br from-teal-500 to-green-500 flex items-center justify-center shadow-md shadow-teal-500/30">
                            <Upload className="w-16 h-16 text-white" />
                            <input
                              id="fileInput"
                              type="file"
                              className="hidden"
                              onChange={handleResumeChange}
                              accept=".zip"
                            />
                          </div>
                          <div className="text-center space-y-4">
                            <h3 className="text-2xl font-semibold text-white">
                              AI Resume Scanner
                            </h3>
                            <p className="text-pink-500 font-medium">
                              {resumeName || "Upload Your Resume"}
                            </p>
                            <div className="flex items-center justify-center space-x-2 text-sm text-gray-300">
                              <span>Advanced parsing technology</span>
                            </div>
                            <p className="text-sm text-gray-300 font-medium">
                              Supported formats: Zip
                            </p>
                          </div>
                        </>
                      )}
                      <div className="grid grid-cols-3 gap-4 text-center text-sm">
                        {[
                          { icon: Scan, text: "AI-Powered Analysis" },
                          { icon: FileText, text: "Smart Parsing" },
                          { icon: CheckCircle2, text: "Instant Results" },
                        ].map(({ icon: Icon, text }) => (
                          <div
                            key={text}
                            className="flex items-center justify-center space-x-2 text-pink-500 font-medium"
                          >
                            <Icon className="w-4 h-4" />
                            <span>{text}</span>
                          </div>
                        ))}
                      </div>
                    </div>
              <FormControl>
                <InputLabel style={{ color: "rgba(216, 216, 216, 1)"}}>Select Job Position</InputLabel>
                      <Select
                        value={selectedID}
                        onChange={handleJobPositionChange}
                        className="glass-effect bg-white/30 backdrop-blur-sm rounded-xl"
                        style={{
                          fontFamily: "Poppins",
                          borderRadius: "8px",
                          color: "rgba(216, 216, 216, 1)",
                        }}
                        label="Select Job Position"
                        MenuProps={{
                          PaperProps: {
                            style: {
                              background:
                                "linear-gradient(233.57deg, rgba(34, 52, 97, 0.25) -5.01%, rgba(11, 10, 31, 0.05) 100%)",
                              opacity: 1,
                              backdropFilter: "blur(500px)",
                              color: "rgba(216, 216, 216, 1)",
                            },
                          },
                        }}
                        sx={{
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(229, 229, 229, 0.5)",
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(229, 229, 229, 0.75)",
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(229, 229, 229, 1)",
                          },
                          "& .MuiSvgIcon-root.MuiSelect-icon": {
                            color: "rgba(216, 216, 216, 1) !important",
                          },
                        }}
                      >
                      {Object.keys(jobPositions).length > 0 ? (
                        Object.entries(jobPositions).map(([id, position]) => (
                          <MenuItem key={id} value={id}>
                            {position.job_role}
                          </MenuItem>
                        ))
                      ) : (
                        Array.from({ length: 5 }).map((_, index) => (
                          <MenuItem key={index} disabled>
                            <Skeleton className="text-gray-500" variant="text" width="100%" />
                          </MenuItem>
                        ))
                      )}
                      </Select>
              </FormControl>
            </div>
            {(isLoading || jobDescription) && (
              <div className="space-y-3 w-full">
                <div className="flex items-center justify-between mt-10">
                  <label className="block text-sm font-medium text-gray-300">
                    Job Description
                  </label>
                  {!isLoading && (
                    <button
                      onClick={toggleExpand}
                      className="button text-blue-600 hover:text-blue-700 text-sm font-medium flex items-center gap-1"
                    >
                      <span className="flex gap-2 items-center">
                        {isExpanded ? "Show Less" : "Expand"}
                        <ChevronRight
                          className={`h-4 w-4 transition-transform duration-300 ${isExpanded ? "rotate-90" : ""}`}
                        />
                      </span>
                    </button>
                  )}
                </div>
                <div className="relative rounded-2xl overflow-hidden">
                  {isLoading ? (
                    <div className="space-y-3 p-6 bg-gray-400/50 backdrop-blur-sm border border-gray-200/50 rounded-2xl">
                      <Skeleton variant="text" width="10%" height={34} />
                      <Skeleton variant="text" width="20%" height={34} />
                      <Skeleton variant="text" width="90%" height={34} />
                      <Skeleton variant="text" width="90%" height={34} />
                      <Skeleton variant="text" width="90%" height={34} />
                    </div>
                  ) : (
                    <div className={`${isExpanded ? "h-auto" : "max-h-52 overflow-hidden"}`}>
                      <pre className="glass-effect text-gray-300 font-sans text-sm whitespace-pre-wrap break-words p-6 bg-gray-50/50 backdrop-blur-sm border border-gray-200/50 rounded-2xl">
                        {formattedDescription || "No job description available."}
                      </pre>
                      {!isExpanded && (
                        <div className="absolute bottom-0 left-0 right-0 h-12 bg-gradient-to-t from-[#69007f] to-transparent" />
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}
            {isExpanded && (
              <Dialog
                open={isExpanded}
                onClose={toggleExpand}
                maxWidth="md"
                fullWidth
                PaperProps={{
                  sx: {
                    background: 'linear-gradient(to bottom right, rgba(34,52,97,0.25), rgba(11,10,31,0.05))',
                    backdropFilter: 'blur(50px)',
                    WebkitBackdropFilter: 'blur(50px)' // for Safari support
                  }
                }}
              >
                <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Typography variant="h6" className="text-gray-300">Complete Job Description</Typography>
                  <button className="button" onClick={toggleExpand} color="secondary">
                    <span>Close</span>
                  </button>
                </DialogTitle>
                <DialogContent dividers>
                  <Typography
                    className="text-gray-300"
                    component="pre"
                    variant="body2"
                    sx={{
                      whiteSpace: 'pre-wrap',
                      wordBreak: 'break-word',
                      fontFamily: 'inherit',
                      background: 'rgba(105, 13, 197, 0.103)',
                      p: 2,
                      borderRadius: 2
                    }}
                  >
                    {formattedDescription || "No job description available."}
                  </Typography>
                </DialogContent>
              </Dialog>
            )}
            {jobDescription && (
              <div className="flex justify-center items-center">
                <button className="button w-48 mt-4" onClick={handleSubmit}><span>Analyze</span></button>
              </div>
            )}
            {errorMessage && <p className="error">{errorMessage}</p>}
          </div>
        </div>
      </div>
    </Authenticator>
  );
};
export default BulkResumeUpload;
