import React, { useState, useEffect, useRef } from "react";
import {
  Card,
  CardContent,
  Typography,
  Icon,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { GetApp } from "@mui/icons-material";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import axios from "axios";
import { useParams } from "react-router-dom";
import host from "../../global";
import PlayCircleOutlineRoundedIcon from "@mui/icons-material/PlayCircleOutlineRounded";
import StopCircleRoundedIcon from "@mui/icons-material/StopCircleRounded";
import { toast } from "react-toastify";
import Cookies from 'js-cookie'

export default function InterviewEvaluationReport({ name, interview_percent }) {
  const { interview_id } = useParams();
  const [evaluationData, setEvaluationData] = useState({});
  const [loading, setLoading] = useState(true);
  const [audioPlaying, setAudioPlaying] = useState(false);
  const [audioIndex, setAudioIndex] = useState(null);
  const reportRef = useRef();
  const audioRefs = useRef([]);
  const finalRemark = interview_percent > 60 ? "Selected" : "Rejected";
  const accessToken = Cookies.get("accessToken")
  
  useEffect(() => {
    const fetchEvaluationResults = async () => {
      try {
        const response = await axios.get(
          `${host}/evaluation`,{
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
            params: {
              interview_id: interview_id,
            }
          });
        const {
          questions,
          answers,
          scores,
          remarks,
          audio_urls,
          startDateTime,
        } = response.data;
        setEvaluationData({
          questions,
          answers,
          scores,
          remarks,
          audio_urls,
          startDateTime,
        });
        setLoading(false);
      } catch (error) {
        if (error.response) {
          const status = error.response.status;
          const detail = error.response.data.detail;
          if (status === 401) {
              await new Promise((resolve) => {
                Cookies.remove('accessToken');
                resolve();
              });
              toast.error(`Unauthorized: ${detail}`);
          } else if (status === 404) {
              toast.warning(`Not Found: ${detail}`);
          } else {
              toast.error(`Error: ${detail}`);
          }
      } else {
          toast.error('An unexpected error occurred. Please try again.');
      }
        setLoading(false);
      }
    };

    fetchEvaluationResults();
  }, [interview_id,accessToken]);

  const handleDownloadPDF = () => {
    html2canvas(reportRef.current, {
      onclone: (document) => {
        const elementsToHide = document.querySelectorAll(".no-print");
        elementsToHide.forEach((el) => (el.style.display = "none"));
      },
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();

      const marginTop = 10;
      const marginLeft = 10;
      const marginBottom = 10;
      const marginRight = 10;

      const imgWidth = pdf.internal.pageSize.width - marginLeft - marginRight;
      const pageHeight = pdf.internal.pageSize.height;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      let heightLeft = imgHeight;

      let position = marginTop;
      pdf.addImage(imgData, "PNG", marginLeft, position, imgWidth, imgHeight);
      heightLeft -= pageHeight - marginBottom;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight + marginTop;
        pdf.addPage();
        pdf.addImage(imgData, "PNG", marginLeft, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      pdf.save(`${name}_Interview_Evaluation_Report.pdf`);
    });
  };

  const toggleAudio = (index) => {
    if (audioIndex === index && audioPlaying) {
      audioRefs.current[index].pause();
      setAudioPlaying(false);
      setAudioIndex(null);
    } else {
      audioRefs.current[index].play();
      setAudioPlaying(true);
      setAudioIndex(index);
      audioRefs.current.forEach((audio, i) => {
        if (i !== index) {
          audio.pause();
        }
      });
    }
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="glass-effect" style={{ margin: "0 auto", padding: "16px" }} ref={reportRef}>
      <Card elevation={3}>
        <CardContent
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingBottom: "5px",
            border: "1px solid rgba(229, 229, 229, 0.5)",
            borderRadius:"8px"
          }}
        >
          <Typography variant="h5" fontWeight="bold" color="white">
            Interview Evaluation Report -
            <Typography
              variant="h6"
              fontWeight="normal"
              color="white"
              style={{ display: "inline", marginLeft: "5px" }}
            >
              {name}
            </Typography>
          </Typography>
          <IconButton onClick={handleDownloadPDF} className="no-print">
            <GetApp style={{color:"white"}}/>
          </IconButton>
        </CardContent>
        <CardContent>
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              borderBottom: "1px solid #e0e0e0",
            }}
          >
            <Typography variant="body1" color="white">
              Remark:{" "}
              <span
                style={{
                  color: finalRemark === "Selected" ? "green" : "red",
                  fontWeight: "bold",
                }}
              >
                {finalRemark}
              </span>
            </Typography>

            <Typography variant="body1" color="white">
              Percent Score: {interview_percent || "0"}%
            </Typography>
            <Typography variant="body1" color="white">
              {evaluationData.startDateTime || "NA"}
            </Typography>
          </div>

          {evaluationData.questions &&
            evaluationData.questions.map((question, index) => {
              return (
                <div
                  key={index}
                  style={{
                    marginTop: "16px",
                    display: "flex",
                    flexDirection: "column",
                    paddingBottom: "10px",
                    borderBottom: "1px solid #A09F9F",
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    color="white"
                  >
                    <pre
                      style={{
                        overflowWrap: "break-word",
                        whiteSpace: "pre-wrap",
                        wordWrap: "break-word",
                        margin: 0,
                        fontFamily: "Poppins",
                      }}
                    >
                      {index + 1}. {question}
                    </pre>
                  </Typography>
                  <Typography variant="body" color="white">
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      Answer:
                    </span>{" "}
                    {evaluationData.answers[index] || "NA"}
                  </Typography>
                  <Typography variant="body" color="white">
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      Score:{" "}
                    </span>
                    {evaluationData.scores[index] || "NA"}
                  </Typography>
                  <Typography variant="body" color="white">
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      {" "}
                      Remark:
                    </span>{" "}
                    {evaluationData.remarks[index] || "NA"}
                  </Typography>
                  <Icon
                    onClick={() => toggleAudio(index)}
                    style={{height:"fit-content"}}
                    className="no-print"
                  >
                    {audioPlaying && audioIndex === index ? (
                      <StopCircleRoundedIcon style={{color:"white"}}/>
                    ) : (
                      <PlayCircleOutlineRoundedIcon style={{color:"white"}}/>
                    )}
                  </Icon>
                  <audio
                    ref={(el) => (audioRefs.current[index] = el)}
                    src={evaluationData.audio_urls[index]}
                  />
                </div>
              );
            })}
        </CardContent>
      </Card>
    </div>
  );
}
